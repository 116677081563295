import React from 'react';
import Button from 'shared/button';
import { Container, Flex, Box, Grid } from './grid';
import { Heading, CroppedText } from './typography';
import { Link } from 'shared/typography';
interface Props {
  children: any;
  onRetry?: () => void;
}
interface State {
  hasError: boolean;
  error: any;
  errorInfo: any;
  attemptedRecovery: boolean;
  eventId: any;
}
export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
    attemptedRecovery: false,
    eventId: null
  };
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    // Sentry.captureException(error, { extra: errorInfo });
    Sentry?.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId
      });
    });
  }
  render() {
    if (this.state.hasError) {
      return <Container data-sentry-element="Container" data-sentry-component="ErrorBoundary" data-sentry-source-file="error-boundry.tsx">
          <Flex alignItems="center" justifyContent="center" height="100vh" data-sentry-element="Flex" data-sentry-source-file="error-boundry.tsx">
            <Grid gridGap={4} sx={{
            justifyItems: 'center',
            textAlign: 'center'
          }} data-sentry-element="Grid" data-sentry-source-file="error-boundry.tsx">
              <Heading as="h2" fontSize={4} className="title has-text-centered" data-sentry-element="Heading" data-sentry-source-file="error-boundry.tsx">
                Oops, something went wrong!
              </Heading>
              <CroppedText data-sentry-element="CroppedText" data-sentry-source-file="error-boundry.tsx">
                Our team has been notified. If the problem persists, please
                contact{' '}
                <Link href="mailto:help@bookem.com?subject=Bug report" data-sentry-element="Link" data-sentry-source-file="error-boundry.tsx">
                  help@bookem.com
                </Link>
              </CroppedText>
              <Flex flexWrap="wrap" data-sentry-element="Flex" data-sentry-source-file="error-boundry.tsx">
                <Box data-sentry-element="Box" data-sentry-source-file="error-boundry.tsx">
                  {/* @ts-ignore */}
                  <Button onClick={() => window.location.reload(true)} data-sentry-element="Button" data-sentry-source-file="error-boundry.tsx">
                    Reload the page
                  </Button>
                </Box>
                {Sentry && Sentry?.getClient?.()?.getOptions()?.enabled && <Button variant="outlined" ml={2} onClick={() => Sentry.showReportDialog({
                eventId: this.state.eventId
              })}>
                    Report feedback
                  </Button>}
              </Flex>
            </Grid>
          </Flex>
        </Container>;
    }
    return this.props.children;
  }
}
export class ViewErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
    attemptedRecovery: false,
    eventId: null
  };
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    Sentry?.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId
      });
    });
  }
  reset = () => {
    this.props.onRetry?.();
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
      attemptedRecovery: true,
      eventId: null
    });
    setTimeout(() => {
      if (!this.state.error) {
        this.setState({
          attemptedRecovery: false
        });
      }
    }, 5000);
  };
  render() {
    if (this.state.hasError) {
      return <Container flex="1" data-sentry-element="Container" data-sentry-component="ViewErrorBoundary" data-sentry-source-file="error-boundry.tsx">
          <Flex alignItems="center" justifyContent="center" height="100%" data-sentry-element="Flex" data-sentry-source-file="error-boundry.tsx">
            <Grid gridGap={4} sx={{
            justifyItems: 'center',
            textAlign: 'center'
          }} data-sentry-element="Grid" data-sentry-source-file="error-boundry.tsx">
              <Heading as="h2" fontSize={4} className="title has-text-centered" data-sentry-element="Heading" data-sentry-source-file="error-boundry.tsx">
                Oops, something went wrong!
              </Heading>
              {this.state.attemptedRecovery ? <>
                  <p>That didn't work.</p>
                  <p>
                    If the problem persists, please contact{' '}
                    <Link href="mailto:help@bookem.com?subject=Bug report">
                      help@bookem.com
                    </Link>
                  </p>
                  <Flex flexWrap="wrap">
                    {/* @ts-ignore */}
                    <Button onClick={() => window.location.reload(true)}>
                      Reload the page
                    </Button>
                    {Sentry?.getClient?.().getOptions().enabled && <Button variant="outlined" ml={2} onClick={() => Sentry.showReportDialog({
                  eventId: this.state.eventId
                })}>
                        Report feedback
                      </Button>}
                  </Flex>
                </> : <>
                  <p>Our team has been notified.</p>{' '}
                  <Flex>
                    <Button onClick={this.reset}>Give it another shot</Button>
                    {Sentry?.getClient?.()?.getOptions()?.enabled && <Button variant="outlined" ml={2} onClick={() => Sentry.showReportDialog({
                  eventId: this.state.eventId
                })}>
                        Report feedback
                      </Button>}
                  </Flex>
                </>}
            </Grid>
          </Flex>
        </Container>;
    }
    return this.props.children;
  }
}