import { c as _c } from "react/compiler-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation, faCheckCircle, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Box, BoxProps, Flex } from '../grid';
import { Theme, ThemeContext } from '@emotion/react';
import { themeGet } from '@styled-system/theme-get';
interface CalloutProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  theme?: Theme;
  close?: () => void;
}
type Props = CalloutProps & BoxProps;
export function Callout(t0) {
  const $ = _c(25);
  let children;
  let close;
  let color;
  let props;
  if ($[0] !== t0) {
    ({
      color,
      children,
      close,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = children;
    $[2] = close;
    $[3] = color;
    $[4] = props;
  } else {
    children = $[1];
    close = $[2];
    color = $[3];
    props = $[4];
  }
  const theme = React.useContext(ThemeContext as any);
  const t1 = color || "primary";
  let backgroundColor;
  let colorScaleName;
  if ($[5] !== t1 || $[6] !== theme) {
    const color_ = t1.split(".")[0];
    colorScaleName = {
      success: "green",
      warning: "yellow",
      alert: "red",
      info: "blue",
      primary: "primary_"
    }[color_] || color_;
    const isDark = theme.mode === "dark";
    if (!isDark) {
      backgroundColor = themeGet(`colors.${colorScaleName}.${isDark ? 6 : 0}`, colorScaleName)({
        theme
      });
    } else {
      backgroundColor = "gray.2";
    }
    $[5] = t1;
    $[6] = theme;
    $[7] = backgroundColor;
    $[8] = colorScaleName;
  } else {
    backgroundColor = $[7];
    colorScaleName = $[8];
  }
  const t2 = color !== "white" ? "heading" : "body";
  let t3;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      color: "text"
    };
    $[9] = t3;
  } else {
    t3 = $[9];
  }
  let t4;
  if ($[10] !== backgroundColor || $[11] !== t2) {
    t4 = {
      color: "primary",
      borderRadius: 2,
      px: 4,
      py: 4,
      fontSize: 2,
      fontFamily: t2,
      position: "relative",
      border: "text",
      backgroundColor,
      "&, & > svg": t3,
      borderColor: "transparent",
      borderWidth: 1,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      gap: 4
    };
    $[10] = backgroundColor;
    $[11] = t2;
    $[12] = t4;
  } else {
    t4 = $[12];
  }
  let t5;
  if ($[13] !== colorScaleName) {
    t5 = ["yellow", "red", "green"].includes(colorScaleName) && <Box color={`${colorScaleName}.5`}><FontAwesomeIcon icon={{
        yellow: faTriangleExclamation,
        red: faTimes,
        green: faCheckCircle,
        blue: faCircleInfo
      }[colorScaleName]} /></Box>;
    $[13] = colorScaleName;
    $[14] = t5;
  } else {
    t5 = $[14];
  }
  let t6;
  if ($[15] !== children) {
    t6 = <Box flexGrow={1} alignItems="center">{children}</Box>;
    $[15] = children;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  let t7;
  if ($[17] !== close) {
    t7 = close && <Box as="button" className="close" onClick={close} alignItems="flex-start"><FontAwesomeIcon icon={faTimes} /></Box>;
    $[17] = close;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  let t8;
  if ($[19] !== props || $[20] !== t4 || $[21] !== t5 || $[22] !== t6 || $[23] !== t7) {
    t8 = <Flex __css={t4} {...props}>{t5}{t6}{t7}</Flex>;
    $[19] = props;
    $[20] = t4;
    $[21] = t5;
    $[22] = t6;
    $[23] = t7;
    $[24] = t8;
  } else {
    t8 = $[24];
  }
  return t8;
}
export const WarningBox = ({
  close,
  children,
  ...rest
}) => <Callout {...rest} color="warning" sx={{
  display: 'flex',
  alignItems: 'center',
  a: {
    color: '#765b1e'
  }
}} close={close} data-sentry-element="Callout" data-sentry-component="WarningBox" data-sentry-source-file="index.tsx">
    {children}
  </Callout>;
export default Callout;