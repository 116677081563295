import { faEnvelope, faMapMarker, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocationId } from '../../hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar } from 'shared/avatar';
import { Box, Flex, Grid } from 'shared/grid';
import { CroppedText, Heading, Link } from 'shared/typography';
import { useAppSelector } from '../../store';
import { BookStepHeading } from '../shared';
const ContactDetails = ({
  location
}) => <>
    {location.email && <Box sx={{
    whiteSpace: 'nowrap'
  }}>
        <FontAwesomeIcon icon={faEnvelope} />{' '}
        <Link href={`mailto:${location.email}`}>{location.email}</Link>
      </Box>}
    {location.tel && <Box sx={{
    whiteSpace: 'nowrap'
  }}>
        <FontAwesomeIcon icon={faPhone} />{' '}
        <Link href={`tel:${location.tel}`}>{location.tel}</Link>
      </Box>}
  </>;
const LocationsView = () => {
  const [locationId] = useLocationId();
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  const locationData = useAppSelector(state_0 => state_0.locations.data);
  const locations = React.useMemo(() => Object.values<any>(locationData).filter(l => !l.is_deleted).filter(l_0 => l_0.business_id === business.id), [business.id, locationData]);
  return <>
      <Grid gridGap={8} data-sentry-element="Grid" data-sentry-source-file="component.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="component.tsx">
          <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')} data-sentry-element="BookStepHeading" data-sentry-source-file="component.tsx">
            {business.description || locations.length == 1 ? <FormattedMessage id="About" defaultMessage="About" /> : <FormattedMessage id="Locations" defaultMessage="Locations" />}
          </BookStepHeading>

          {business.description && <CroppedText>
              {business.description.replace('\n\n', '\n').split('\n').map((line, idx) => line ? <Box as="p" mb="1em" key={idx}>
                      {line}
                    </Box> : <br key={idx} />)}
            </CroppedText>}
        </Grid>

        {locations.map((location, idx_0) => <Flex key={idx_0} flexWrap="wrap" sx={{
        gap: 6
      }}>
            <Link rel="noopener noreferrer" target={location.is_virtual ? null : '_blank'} href={location.is_virtual ? '#' : `https://maps.google.com/maps?&z=16&q=${location.gps[0]}+${location.gps[1]}&ll=${location.gps[0]}+${location.gps[1]}`}>
              <Avatar size={100} image={location.is_virtual ? null : `https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${location.gps[0]},${location.gps[1]}&zoom=14&size=150x150&sensor=false&markers=color:red|${location.gps[0]},${location.gps[1]}`} name={location.name} icon={faMapMarker} />
            </Link>

            <Box>
              <Grid key={idx_0} gridGap={2}>
                {locations.length > 1 && <Heading fontSize={3} as="h3">
                    {location.name}
                  </Heading>}

                {location.is_virtual ? <ContactDetails location={location} /> : <Grid gridGap={2}>
                    <Box>
                      {[...(location.street_address || '').split('\n'), location.city, location.postal_code, location.province].map((line_0, idx_1) => <div key={idx_1}>{line_0}</div>)}
                    </Box>
                    <ContactDetails location={location} />
                  </Grid>}
              </Grid>
            </Box>
          </Flex>)}
      </Grid>
    </>;
};
export default LocationsView;