import { c as _c } from "react/compiler-runtime";
import React from 'react';
import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { BoxProps, Box } from '../grid';
import { Link, Heading } from '../typography';
import isUrl from 'is-url';
import { Link as ReachLink } from 'react-router-dom';
import { Text as TextE } from '../typography';
import { EMPTY_OBJECT } from 'shared/utils/constants';
import { getCdnImageUrlFromPath } from '../utils/images';
export const RenderCommon = t0 => {
  const $ = _c(51);
  const {
    element,
    children,
    attributes: t1
  } = t0;
  let t2;
  if ($[0] !== t1) {
    t2 = t1 === undefined ? {} : t1;
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const attributes = t2;
  switch (element.type) {
    case "block-quote":
      {
        let t3;
        if ($[2] !== attributes || $[3] !== children) {
          t3 = <Box as="blockquote" borderLeftColor="gray.5" borderLeftStyle="solid" borderLeftWidth="3px" p="1em" mt={1} mb="1em" {...attributes}>{children}</Box>;
          $[2] = attributes;
          $[3] = children;
          $[4] = t3;
        } else {
          t3 = $[4];
        }
        return t3;
      }
    case "heading-one":
      {
        let t3;
        if ($[5] !== attributes || $[6] !== children) {
          t3 = <Heading fontSize={5} as="h1" mt="0.5em" mb="0.5em" {...attributes}>{children}</Heading>;
          $[5] = attributes;
          $[6] = children;
          $[7] = t3;
        } else {
          t3 = $[7];
        }
        return t3;
      }
    case "title":
      {}
    case "heading-two":
      {
        let t3;
        if ($[8] !== attributes || $[9] !== children) {
          t3 = <Heading fontSize={4} as="h2" mt="0.75em" mb="0.5em" {...attributes}>{children}</Heading>;
          $[8] = attributes;
          $[9] = children;
          $[10] = t3;
        } else {
          t3 = $[10];
        }
        return t3;
      }
    case "heading-three":
      {
        let t3;
        if ($[11] !== attributes || $[12] !== children) {
          t3 = <Heading fontSize={3} as="h3" mt="0.75em" mb="0.5em" {...attributes}>{children}</Heading>;
          $[11] = attributes;
          $[12] = children;
          $[13] = t3;
        } else {
          t3 = $[13];
        }
        return t3;
      }
    case "list-item":
      {
        let t3;
        if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
          t3 = {
            "& p": {
              mb: "0.5em"
            }
          };
          $[14] = t3;
        } else {
          t3 = $[14];
        }
        let t4;
        if ($[15] !== attributes || $[16] !== children) {
          t4 = <Box as="li" ml="1em" sx={t3} {...attributes}>{children}</Box>;
          $[15] = attributes;
          $[16] = children;
          $[17] = t4;
        } else {
          t4 = $[17];
        }
        return t4;
      }
    case "bulleted-list":
      {
        let t3;
        if ($[18] !== attributes || $[19] !== children) {
          t3 = <Box as="ul" mb="1em" ml="1em" {...attributes}>{children}</Box>;
          $[18] = attributes;
          $[19] = children;
          $[20] = t3;
        } else {
          t3 = $[20];
        }
        return t3;
      }
    case "numbered-list":
      {
        let t3;
        if ($[21] !== attributes || $[22] !== children) {
          t3 = <Box as="ol" mb="1em" {...attributes}>{children}</Box>;
          $[21] = attributes;
          $[22] = children;
          $[23] = t3;
        } else {
          t3 = $[23];
        }
        return t3;
      }
    case "paragraph":
      {
        let t3;
        if ($[24] !== attributes || $[25] !== children) {
          t3 = <Box as="p" mb="1em" {...attributes}>{children}</Box>;
          $[24] = attributes;
          $[25] = children;
          $[26] = t3;
        } else {
          t3 = $[26];
        }
        return t3;
      }
    case "linebreak":
      {
        let t3;
        if ($[27] === Symbol.for("react.memo_cache_sentinel")) {
          t3 = <br />;
          $[27] = t3;
        } else {
          t3 = $[27];
        }
        return t3;
      }
    case "table":
      {
        let t3;
        if ($[28] !== element.colWidths) {
          t3 = element.colWidths || [];
          $[28] = element.colWidths;
          $[29] = t3;
        } else {
          t3 = $[29];
        }
        const colWidths = t3;
        let colgroup = null;
        if (colWidths) {
          let t4;
          if ($[30] !== colWidths) {
            t4 = colWidths.map(_temp);
            $[30] = colWidths;
            $[31] = t4;
          } else {
            t4 = $[31];
          }
          const cols = t4;
          let t5;
          if ($[32] !== cols) {
            t5 = <colgroup>{cols}</colgroup>;
            $[32] = cols;
            $[33] = t5;
          } else {
            t5 = $[33];
          }
          colgroup = t5;
        }
        let t4;
        if ($[34] === Symbol.for("react.memo_cache_sentinel")) {
          t4 = {
            overflowWrap: "anywhere",
            borderCollapse: "collapse",
            borderSpacing: 0,
            tableLayout: "fixed",
            width: "max-content",
            maxWidth: "100%",
            border: "1px solid #bbb"
          };
          $[34] = t4;
        } else {
          t4 = $[34];
        }
        let t5;
        if ($[35] !== children || $[36] !== colgroup) {
          t5 = <table style={t4}>{colgroup}{children}</table>;
          $[35] = children;
          $[36] = colgroup;
          $[37] = t5;
        } else {
          t5 = $[37];
        }
        return t5;
      }
    case "table-row":
      {
        let t3;
        if ($[38] !== children) {
          t3 = <tr>{children}</tr>;
          $[38] = children;
          $[39] = t3;
        } else {
          t3 = $[39];
        }
        return t3;
      }
    case "table-cell":
      {
        let width = "56px";
        let elType = "td";
        let background = "transparent";
        if (element.headerState > 0) {
          elType = "th";
          background = "#f0f0f0";
        }
        if (element.width) {
          width = `${element.width}px`;
        }
        const t3 = elType as any;
        const t4 = element.colSpan || 1;
        const t5 = element.rowSpan || 1;
        let t6;
        if ($[40] !== background || $[41] !== width) {
          t6 = {
            position: "relative",
            width,
            padding: "6px 8px",
            border: "1px solid #bbb",
            backgroundColor: background
          };
          $[40] = background;
          $[41] = width;
          $[42] = t6;
        } else {
          t6 = $[42];
        }
        let t7;
        if ($[43] !== children || $[44] !== t3 || $[45] !== t4 || $[46] !== t5 || $[47] !== t6) {
          t7 = <Box as={t3} colSpan={t4} rowSpan={t5} style={t6}>{children}</Box>;
          $[43] = children;
          $[44] = t3;
          $[45] = t4;
          $[46] = t5;
          $[47] = t6;
          $[48] = t7;
        } else {
          t7 = $[48];
        }
        return t7;
      }
    default:
      {
        let t3;
        if ($[49] !== children) {
          t3 = <>{children}</>;
          $[49] = children;
          $[50] = t3;
        } else {
          t3 = $[50];
        }
        return t3;
      }
  }
};
export const RenderElement = t0 => {
  const $ = _c(46);
  const {
    value: element,
    elementOverrides: t1,
    variables: t2
  } = t0;
  const elementOverrides = t1 === undefined ? EMPTY_OBJECT : t1;
  const variables = t2 === undefined ? EMPTY_OBJECT : t2;
  if (Text.isText(element)) {
    let props;
    if ($[0] !== element.bold || $[1] !== element.italic || $[2] !== element.underline) {
      props = {};
      if (element.bold) {
        props.fontWeight = "heading";
      }
      if (element.italic) {
        props.fontStyle = "italic";
      }
      if (element.underline) {
        let t3;
        if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
          t3 = {
            textDecoration: "underline"
          };
          $[4] = t3;
        } else {
          t3 = $[4];
        }
        props.sx = t3;
      }
      $[0] = element.bold;
      $[1] = element.italic;
      $[2] = element.underline;
      $[3] = props;
    } else {
      props = $[3];
    }
    let t3;
    if ($[5] !== element.text || $[6] !== props) {
      t3 = <TextE as="span" fontSize="inherit" {...props}>{element.text}</TextE>;
      $[5] = element.text;
      $[6] = props;
      $[7] = t3;
    } else {
      t3 = $[7];
    }
    return t3;
  }
  const t3 = element.children;
  let children;
  let t4;
  if ($[8] !== element || $[9] !== elementOverrides || $[10] !== variables) {
    t4 = Symbol.for("react.early_return_sentinel");
    bb0: {
      let t5;
      if ($[13] !== elementOverrides || $[14] !== variables) {
        t5 = (v, idx) => <RenderElement key={idx} value={v} elementOverrides={elementOverrides} variables={variables} />;
        $[13] = elementOverrides;
        $[14] = variables;
        $[15] = t5;
      } else {
        t5 = $[15];
      }
      children = t3.map(t5);
      const override = elementOverrides[element.type];
      if (override) {
        t4 = override(element, children);
        break bb0;
      }
    }
    $[8] = element;
    $[9] = elementOverrides;
    $[10] = variables;
    $[11] = children;
    $[12] = t4;
  } else {
    children = $[11];
    t4 = $[12];
  }
  if (t4 !== Symbol.for("react.early_return_sentinel")) {
    return t4;
  }
  switch (element.type) {
    case "link":
      {
        if (isUrl(element.url)) {
          let t5;
          let u;
          if ($[16] !== element.url) {
            u = new URL(element.url);
            t5 = u.host.includes("app.bookem.");
            $[16] = element.url;
            $[17] = t5;
            $[18] = u;
          } else {
            t5 = $[17];
            u = $[18];
          }
          if (t5) {
            const path = u.pathname;
            let t6;
            if ($[19] !== children || $[20] !== path) {
              t6 = <Link as={ReachLink} to={path}>{children}</Link>;
              $[19] = children;
              $[20] = path;
              $[21] = t6;
            } else {
              t6 = $[21];
            }
            return t6;
          }
        }
        let t5;
        if ($[22] !== element.url) {
          t5 = escapeHtml(element.url);
          $[22] = element.url;
          $[23] = t5;
        } else {
          t5 = $[23];
        }
        let t6;
        if ($[24] !== children || $[25] !== t5) {
          t6 = <Link target="_blank" href={t5}>{children}</Link>;
          $[24] = children;
          $[25] = t5;
          $[26] = t6;
        } else {
          t6 = $[26];
        }
        return t6;
      }
    case "image":
      {
        const t5 = `${element.width ?? 100}%`;
        let t6;
        if ($[27] !== t5) {
          t6 = ["100%", t5];
          $[27] = t5;
          $[28] = t6;
        } else {
          t6 = $[28];
        }
        const t7 = `min(${element.naturalWidth}px, 100%)`;
        let t8;
        if ($[29] !== element.url) {
          t8 = getCdnImageUrlFromPath(element.url);
          $[29] = element.url;
          $[30] = t8;
        } else {
          t8 = $[30];
        }
        let t9;
        if ($[31] !== t6 || $[32] !== t7 || $[33] !== t8) {
          t9 = <Box as="img" alt="image" width={t6} maxWidth={t7} src={t8} mb="1em" />;
          $[31] = t6;
          $[32] = t7;
          $[33] = t8;
          $[34] = t9;
        } else {
          t9 = $[34];
        }
        return t9;
      }
    case "horizontalrule":
      {
        let t5;
        if ($[35] === Symbol.for("react.memo_cache_sentinel")) {
          t5 = <Box as="hr" my={4} />;
          $[35] = t5;
        } else {
          t5 = $[35];
        }
        return t5;
      }
    case "video":
      {
        let t5;
        if ($[36] === Symbol.for("react.memo_cache_sentinel")) {
          t5 = {
            display: "block",
            width: "100%",
            height: "56.25vw",
            maxHeight: "calc(1068px / 16 * 9)",
            "@supports(aspect-ratio: 16 / 9)": {
              aspectRatio: "16 / 9",
              height: "unset",
              maxHeight: "unset"
            }
          };
          $[36] = t5;
        } else {
          t5 = $[36];
        }
        let t6;
        if ($[37] !== element.url) {
          t6 = <Box as="iframe" sx={t5} src={element.url} frameBorder={0} allowFullScreen={true} mb="1em" />;
          $[37] = element.url;
          $[38] = t6;
        } else {
          t6 = $[38];
        }
        return t6;
      }
    case "placeholder":
      {
        let t5;
        if ($[39] !== element.item.label || $[40] !== element.item.value || $[41] !== variables) {
          t5 = variables[element.item.value] || <Box as="span" sx={{
            padding: "2px 4px",
            margin: "0 1px",
            verticalAlign: "baseline",
            display: "inline-block",
            borderRadius: "4px",
            backgroundColor: "brand0.1",
            color: "primary",
            fontSize: "0.9em"
          }}>{element.item.label}</Box>;
          $[39] = element.item.label;
          $[40] = element.item.value;
          $[41] = variables;
          $[42] = t5;
        } else {
          t5 = $[42];
        }
        return t5;
      }
    default:
      {
        let t5;
        if ($[43] !== children || $[44] !== element) {
          t5 = <RenderCommon element={element}>{children}</RenderCommon>;
          $[43] = children;
          $[44] = element;
          $[45] = t5;
        } else {
          t5 = $[45];
        }
        return t5;
      }
  }
};
const Render = t0 => {
  const $ = _c(9);
  const {
    value,
    elementOverrides: t1,
    variables: t2
  } = t0;
  const elementOverrides = t1 === undefined ? EMPTY_OBJECT : t1;
  const variables = t2 === undefined ? EMPTY_OBJECT : t2;
  let t3;
  if ($[0] !== elementOverrides || $[1] !== value || $[2] !== variables) {
    let t4;
    if ($[4] !== elementOverrides || $[5] !== variables) {
      t4 = (v, idx) => <RenderElement value={v} key={idx} elementOverrides={elementOverrides} variables={variables} />;
      $[4] = elementOverrides;
      $[5] = variables;
      $[6] = t4;
    } else {
      t4 = $[6];
    }
    t3 = value.map(t4);
    $[0] = elementOverrides;
    $[1] = value;
    $[2] = variables;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[7] !== t3) {
    t4 = <>{t3}</>;
    $[7] = t3;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  return t4;
};
export default Render;
function _temp(width_0, idx) {
  return <col style={{
    width: `${width_0}px`
  }} key={idx} data-sentry-component="_temp" data-sentry-source-file="render.tsx" />;
}