import { c as _c } from "react/compiler-runtime";
import { useLocationId } from './hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import Button from 'shared/button';
import { Box, Container, Flex, Grid } from 'shared/grid';
import { StandardModal } from 'shared/modal';
import PoweredBy from 'shared/powered-by';
import { logout } from 'shared/redux/session/actions';
import { updatePreferences } from 'shared/redux/user-preferences/actions';
import { Heading } from 'shared/typography';
import { languages } from 'shared/utils/constants';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { useAppDispatch, useAppSelector } from './store';
import { Text } from 'shared/typography';
import { faAddressBook, faLanguage } from '@fortawesome/pro-solid-svg-icons';
export const Header = () => {
  const $ = _c(40);
  const dispatch = useAppDispatch();
  const [isLanguageModalOpen, setLanguageModalOpen] = React.useState(false);
  let t0;
  if ($[0] !== dispatch) {
    t0 = language => {
      dispatch(updatePreferences({
        language
      }));
    };
    $[0] = dispatch;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const setLanguage = t0;
  const isSessionValid = useAppSelector(_temp);
  const [locationId] = useLocationId();
  const business = useAppSelector(_temp2);
  let t1;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = ["space-between"];
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = ["wrap", "nowrap"];
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  let t4;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = ["wrap", "nowrap"];
    t4 = {
      gap: 2
    };
    $[4] = t3;
    $[5] = t4;
  } else {
    t3 = $[4];
    t4 = $[5];
  }
  const t5 = `/` + (locationId ? `?lid=${locationId}` : "");
  let t6;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      gap: 4
    };
    $[6] = t6;
  } else {
    t6 = $[6];
  }
  let t7;
  if ($[7] !== business.logo_image_id) {
    t7 = business.logo_image_id && <Box position="relative" flexGrow={0} flexShrink={1} flexBasis={[100, 150]}><Box sx={{
        position: "absolute",
        bottom: "0px",
        zIndex: 1
      }}><Flex alignItems="center" justifyContent="center" flexShrink={0} sx={{
          width: [100, 150],
          height: [100, 150],
          borderRadius: "9999px",
          overflow: "hidden",
          objectFit: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${getCdnImageUrlFromId(business.logo_image_id)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderColor: "gray.1",
          borderStyle: "solid",
          borderWidth: "1px",
          boxShadow: "medium"
        }} /></Box><Box width={[100, 150]} height="60px" mr={3} /></Box>;
    $[7] = business.logo_image_id;
    $[8] = t7;
  } else {
    t7 = $[8];
  }
  let t8;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflowWrap: "break-word"
    };
    $[9] = t8;
  } else {
    t8 = $[9];
  }
  let t9;
  if ($[10] !== business.name) {
    t9 = <Box flex="1 1 100px"><Heading as="h1" fontSize={3} color="text" sx={t8}>{business.name}</Heading></Box>;
    $[10] = business.name;
    $[11] = t9;
  } else {
    t9 = $[11];
  }
  let t10;
  if ($[12] !== t5 || $[13] !== t7 || $[14] !== t9) {
    t10 = <Flex className="logo-and-name"><Flex as={ReachLink} justifyContent="center" alignItems="center" to={t5} sx={t6}>{t7}{t9}</Flex></Flex>;
    $[12] = t5;
    $[13] = t7;
    $[14] = t9;
    $[15] = t10;
  } else {
    t10 = $[15];
  }
  let t11;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = {
      gap: 0,
      top: 0,
      right: 0,
      position: ["absolute", "static"],
      backgroundColor: ["background", "transparent"],
      borderBottomLeftRadius: [16, 0]
    };
    $[16] = t11;
  } else {
    t11 = $[16];
  }
  let t12;
  if ($[17] !== dispatch || $[18] !== isSessionValid) {
    t12 = isSessionValid && <Button variant="flat" onClick={() => dispatch(logout())}>Sign out</Button>;
    $[17] = dispatch;
    $[18] = isSessionValid;
    $[19] = t12;
  } else {
    t12 = $[19];
  }
  const t13 = `/contact?lid=${locationId}`;
  let t14;
  if ($[20] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = <Text ml="0.5em" display={["none", "none", "inline"]}><FormattedMessage id="Contact us" defaultMessage="Contact us" /></Text>;
    $[20] = t14;
  } else {
    t14 = $[20];
  }
  let t15;
  if ($[21] !== t13) {
    t15 = <Button as={ReachLink} variant="flat" color="gray" to={t13} noIconPadding={true} iconLeft={faAddressBook}>{t14}</Button>;
    $[21] = t13;
    $[22] = t15;
  } else {
    t15 = $[22];
  }
  let t16;
  if ($[23] === Symbol.for("react.memo_cache_sentinel")) {
    t16 = <Button color="gray" variant="flat" onClick={() => setLanguageModalOpen(true)} iconLeft={faLanguage} />;
    $[23] = t16;
  } else {
    t16 = $[23];
  }
  let t17;
  if ($[24] !== t12 || $[25] !== t15) {
    t17 = <Flex sx={t11}>{t12}{t15}{t16}</Flex>;
    $[24] = t12;
    $[25] = t15;
    $[26] = t17;
  } else {
    t17 = $[26];
  }
  let t18;
  if ($[27] !== t10 || $[28] !== t17) {
    t18 = <Flex bg="background" width="100%" justifyContent={t1} alignItems="center" boxShadow="medium" zIndex={3} flexWrap={t2}><Container py={4} px={6} className="container"><Flex justifyContent="space-between" alignItems="center" flexWrap={t3} sx={t4}>{t10}{t17}</Flex></Container></Flex>;
    $[27] = t10;
    $[28] = t17;
    $[29] = t18;
  } else {
    t18 = $[29];
  }
  let t19;
  if ($[30] === Symbol.for("react.memo_cache_sentinel")) {
    t19 = () => setLanguageModalOpen(false);
    $[30] = t19;
  } else {
    t19 = $[30];
  }
  let t20;
  if ($[31] === Symbol.for("react.memo_cache_sentinel")) {
    t20 = <FormattedMessage id="Public.selectLanguage" defaultMessage="Select a language" />;
    $[31] = t20;
  } else {
    t20 = $[31];
  }
  let t21;
  if ($[32] !== setLanguage) {
    t21 = () => <Grid>{Object.entries(languages).map((t22, idx) => {
        const [code, language_0] = t22;
        return <Button variant="outlined" key={idx} width="100%" onClick={() => {
          setLanguage(code);
          setLanguageModalOpen(false);
        }}>{language_0}</Button>;
      })}</Grid>;
    $[32] = setLanguage;
    $[33] = t21;
  } else {
    t21 = $[33];
  }
  let t22;
  if ($[34] !== isLanguageModalOpen || $[35] !== t21) {
    t22 = <StandardModal close={t19} isOpen={isLanguageModalOpen} title={t20}>{t21}</StandardModal>;
    $[34] = isLanguageModalOpen;
    $[35] = t21;
    $[36] = t22;
  } else {
    t22 = $[36];
  }
  let t23;
  if ($[37] !== t18 || $[38] !== t22) {
    t23 = <>{t18}{t22}</>;
    $[37] = t18;
    $[38] = t22;
    $[39] = t23;
  } else {
    t23 = $[39];
  }
  return t23;
};
export const BareLayout = t0 => {
  const $ = _c(12);
  const {
    children
  } = t0;
  const business = useAppSelector(_temp3);
  const t1 = business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
                to right,
                rgba(0, 0, 0, 0.2), 
                transparent)`;
  let t2;
  if ($[0] !== t1) {
    t2 = <Box sx={{
      height: "10rem",
      backgroundColor: "#fff",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      position: "relative",
      bg: "gray.1",
      backgroundImage: t1
    }} />;
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <Header />;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = [1, 6];
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = [1, 0];
    $[4] = t5;
  } else {
    t5 = $[4];
  }
  let t6;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      flexDirection: "row",
      "& > *": {
        flexGrow: 1
      }
    };
    $[5] = t6;
  } else {
    t6 = $[5];
  }
  let t7;
  if ($[6] !== children) {
    t7 = <Container px={t4} py={6} flex={t5} display="flex" sx={t6}>{children}</Container>;
    $[6] = children;
    $[7] = t7;
  } else {
    t7 = $[7];
  }
  let t8;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = <PoweredBy medium="embed" my={4} />;
    $[8] = t8;
  } else {
    t8 = $[8];
  }
  let t9;
  if ($[9] !== t2 || $[10] !== t7) {
    t9 = <>{t2}{t3}{t7}{t8}</>;
    $[9] = t2;
    $[10] = t7;
    $[11] = t9;
  } else {
    t9 = $[11];
  }
  return t9;
};
const Layout = t0 => {
  const $ = _c(9);
  const {
    children
  } = t0;
  const business = useAppSelector(_temp4);
  const t1 = business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
                to right,
                rgba(0, 0, 0, 0.2), 
                transparent)`;
  let t2;
  if ($[0] !== t1) {
    t2 = <Box sx={{
      height: "10rem",
      backgroundColor: "#fff",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      position: "relative",
      bg: "gray.1",
      backgroundImage: t1
    }} />;
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <Header />;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = <PoweredBy pt={2} />;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== children) {
    t5 = <Flex flex="1"><Container backgroundColor="bodyBackground" flex="1" display="flex" flexDirection="column">{children}{t4}</Container></Flex>;
    $[4] = children;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  let t6;
  if ($[6] !== t2 || $[7] !== t5) {
    t6 = <>{t2}{t3}{t5}</>;
    $[6] = t2;
    $[7] = t5;
    $[8] = t6;
  } else {
    t6 = $[8];
  }
  return t6;
};
export default Layout;
function _temp(state) {
  return state.session.tokenExp > new Date().getTime() / 1000;
}
function _temp2(state_0) {
  return state_0.businesses.data[state_0.public.businessId];
}
function _temp3(state) {
  return state.businesses.data[state.public.businessId];
}
function _temp4(state) {
  return state.businesses.data[state.public.businessId];
}