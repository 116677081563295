import { faCalendar, faCube, faTags, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FORM_ERROR } from 'final-form';
// import { isValidNumber } from 'libphonenumber-js';
import arrayMutators from 'final-form-arrays';
import get from 'lodash.get';
import { DateTime } from 'luxon';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Avatar } from 'shared/avatar';
import Button from 'shared/button';
import Callout from 'shared/callout';
import defaultClientBookingFields from 'shared/default-client-booking-form';
import FormRenderer from 'shared/dynamic-fields/form-renderer';
import FormError from 'shared/form-error';
import { Box, Flex, Grid } from 'shared/grid';
import { useLibPhoneNumber } from 'shared/hooks/phone-number';
import IconWrapper from 'shared/icon-wrapper';
import Input from 'shared/input';
import Label from 'shared/label';
import Loader from 'shared/loader';
import { StandardModal } from 'shared/modal';
import PhoneInput from 'shared/phone-input';
import { fetchBookableBusy } from 'shared/redux/bookable-busy/actions';
import { createBooking } from 'shared/redux/bookings/actions';
import { fetchForm } from 'shared/redux/forms/actions';
import { fetchSchedules } from 'shared/redux/schedules/actions';
import { fetchService } from 'shared/redux/services/actions';
import { addToast } from 'shared/toaster/redux/actions';
import { Text } from 'shared/typography';
import { toBase16 } from 'shared/utils/b58';
import * as btools from 'shared/utils/booking-tools';
import { buildBookableDropdowns } from 'shared/utils/booking-tools';
import { CONFIRM_PUBLIC_BOOKING_POLICY } from 'shared/utils/constants';
import { currencyFormat } from 'shared/utils/currency';
import { getScrollToErrors } from 'shared/utils/form';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import isEmail from 'shared/utils/is-email';
import { getTranslation } from 'shared/utils/text';
import { isUUID } from 'shared/utils/uuid';
import { useBookableIds, useLocationId } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store';
import { BookStepHeading } from '../shared';

// import { useSessionState } from 'shared/hooks/persisted-state';
const isServer = typeof window === 'undefined';
export default function BookView() {
  const [savedFormData, setSavedFormData_] = React.useState<Record<string, any>>({});

  // Only update the window object
  const setSavedFormData = React.useCallback(savedFormData_0 => {
    if (!isServer) {
      window['savedFormData'] = savedFormData_0;
    }
  }, []);

  // Need to init here for SSR
  React.useEffect(() => {
    setSavedFormData_(window['savedFormData'] || {});
  }, []);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    serviceId: serviceId_
  } = useParams();
  const serviceId_0 = React.useMemo(() => {
    let serviceId = serviceId_;
    if (!isUUID(serviceId)) {
      if (serviceId.length > 1 && serviceId.length < 32) {
        serviceId = toBase16(serviceId);
      } else if (serviceId.length !== 32) {
        // Not uuid
        serviceId = null;
      }
    }
    return serviceId;
  }, [serviceId_]);
  const [locationId, setLocationId] = useLocationId();
  const [bookableIds, setBookableIds] = useBookableIds();
  const dispatch = useAppDispatch();
  const [showPolicy, setShowPolicy] = React.useState(false);
  const [showNotAvailable, setShowNotAvailable] = React.useState(false);
  const [wasLoaded, setWasLoaded] = React.useState(false);
  const language = useAppSelector(state => state.userPreferences.language);
  const service = useAppSelector(state_0 => state_0.services.data[serviceId_0]);
  // const businessLanguage = useAppSelector(
  //   (state) => state.businesses.data[state.public.businessId]?.language || 'en'
  // );
  const location = useAppSelector(state_1 => state_1.locations.data[locationId]);
  const bookableData = useAppSelector(state_2 => state_2.bookables.data);
  const bookableGroupData = useAppSelector(state_3 => state_3.bookableGroups.data);
  const isServiceLoading = useAppSelector(state_4 => (state_4.services.status || 'loading') === 'loading');
  if (!service && !isServiceLoading) {
    dispatch(fetchService(serviceId_0));
  }
  const isLoading = useAppSelector(state_5 => [state_5.businesses.status, state_5.locations.status, state_5.services.status, state_5.bookables.status, state_5.bookableGroups.status, state_5.bookableBusy.status].map(v => v || 'loading').includes('loading') || !state_5.public.businessId);
  const start = useAppSelector(state_6 => searchParams.get('start'));
  const end = searchParams.get('end');
  const timezone = searchParams.get('timezone') || location?.timezone;
  const firstname = searchParams.get('firstname');
  const lastname = searchParams.get('lastname');
  const cellphone = searchParams.get('cellphone');
  const email = searchParams.get('email');
  const service_id = serviceId_0;
  const location_id = locationId;
  const bookable_ids = bookableIds;
  const businessId = useAppSelector(state_7 => state_7.public.businessId);
  const business = useAppSelector(state_8 => state_8.businesses.data[state_8.public.businessId]);
  const initialValues = React.useMemo(() => ({
    client: {
      firstname,
      lastname,
      cellphone,
      email
    },
    ...savedFormData,
    business_id: businessId,
    location_id,
    services: [{
      start,
      end,
      service_id,
      bookable_ids,
      ...(savedFormData?.services?.[0] || {}),
      show_pricing: service.show_pricing,
      confirm_public_booking_policy: service.confirm_public_booking_policy
    }]
  }), [firstname, lastname, cellphone, email, savedFormData, businessId, location_id, start, end, service_id, bookable_ids, service.show_pricing, service.confirm_public_booking_policy]);
  const billableItemData = useAppSelector(state_9 => state_9.billableItems.data);
  const invoiceSettings = useAppSelector(state_10 => state_10.invoiceSettings.data[state_10.public.businessId]);
  const locationData = useAppSelector(state_11 => state_11.locations.data);
  const serviceLocation = React.useMemo(() => service ? service.locations.find(sl => sl.location_id === locationId) || null : null, [locationId, service]);
  const price = React.useMemo(() => {
    if (!serviceLocation) {
      return null;
    }
    const duration = (DateTime.fromISO(end, {
      zone: 'UTC'
    }).valueOf() - DateTime.fromISO(start, {
      zone: 'UTC'
    }).valueOf()) / 60000;
    const serviceDuration = serviceLocation.service_durations.find(v_0 => v_0.duration === duration);
    if (!serviceDuration) {
      return null;
    }
    if (serviceDuration.pricing_model === 'hidden') {
      return null;
    }
    const taxRate = invoiceSettings ? invoiceSettings.location_settings[invoiceSettings.is_location_specific ? locationId : '*'].default_tax_rate : 0;
    if (serviceDuration.pricing_model === 'simple') {
      return serviceDuration.price * (1 + taxRate / 100);
    }
    return serviceDuration.billable_items.reduce((total, {
      billable_item_id,
      quantity
    }) => {
      const item = billableItemData[billable_item_id];
      if (!item) {
        return total;
      }
      return total + (quantity || 0) * (item.unit_price[locationId] || 0);
    }, 0) * (1 + taxRate / 100);
  }, [serviceLocation, end, start, invoiceSettings, locationId, billableItemData]);
  const currency = locationData[locationId]?.currency || 'ZAR';
  React.useEffect(() => {
    if (!isLoading) {
      setWasLoaded(true);
    }
  }, [isLoading]);
  React.useEffect(() => {
    if (service?.form_id) {
      dispatch(fetchForm(service.form_id));
    }
  }, [dispatch, service, service?.form_id]);
  const bookingForm = useAppSelector(state_12 => state_12.forms.data[service?.form_id]?.slice(-1)?.[0]);
  const updateAvailability = React.useCallback(() => {
    const [start_0, end_0] = business.settings.booking_window.map(v_1 => DateTime.utc().plus({
      minutes: v_1
    }).toISO().substring(0, 19) + 'Z');
    dispatch(fetchSchedules(business.id));
    dispatch(fetchBookableBusy(business.id, start_0, end_0));
  }, [business.id, business.settings.booking_window, dispatch]);
  const decorators = React.useMemo(() => [getScrollToErrors()], []);
  const requirements = React.useMemo(() => btools.groupSubstitute(serviceLocation?.requirements || [], locationId, bookableData, bookableGroupData), [bookableData, bookableGroupData, locationId, serviceLocation?.requirements]);
  const sourceSchema = bookingForm ? bookingForm.schema : defaultClientBookingFields;
  const schema = React.useMemo(() => sourceSchema.map(v_2 => {
    if (v_2.associated_client_field_name) {
      return {
        ...v_2,
        name: `client.${v_2.associated_client_field_name}`
      };
    }
    return {
      ...v_2,
      name: `services[0].form_data.${v_2.name}`
    };
  }), [sourceSchema]);
  // const hasForms = useHasFeature('forms');

  const {
    isValidNumber
  } = useLibPhoneNumber();
  if (isLoading && !wasLoaded) {
    return <Loader />;
  }
  return <>
      <Grid gridGap={4} data-sentry-element="Grid" data-sentry-source-file="component.tsx">
        <BookStepHeading backTo={`/services/${serviceId_0}` + (locationId ? `?lid=${locationId}` : '')} data-sentry-element="BookStepHeading" data-sentry-source-file="component.tsx">
          {service.confirm_public_booking_policy == CONFIRM_PUBLIC_BOOKING_POLICY.automatic ? <FormattedMessage id="Public.BookView.bookingTab" defaultMessage={`{serviceName} booking`} values={{
          serviceName: getTranslation(service.name, language)
        }} /> : <FormattedMessage id="Public.BookView.requestTab" defaultMessage={`{serviceName} booking request`} values={{
          serviceName: getTranslation(service.name, language)
        }} />}
        </BookStepHeading>
        <Form mutators={arrayMutators as any} decorators={decorators} initialValues={initialValues} onSubmit={values => {
        const uploadsElements = schema.filter(element => element['type'] == 'uploads') || [];
        const isAnyUploading = uploadsElements.some(uploadElement => get(values, uploadElement.name).some(upload => upload['upload_id'] == 'uploading'));
        if (isAnyUploading) {
          dispatch(addToast(`File uploads must finish uploading before the form can be submitted`, 'alert'));
          return;
        }
        if (!showPolicy && business.settings.booking_policy) {
          setShowPolicy(true);
          return;
        }
        return dispatch(createBooking({
          ...values,
          client: {
            ...values.client,
            language: values.client.language || language,
            timezone: service.type !== 'on_premises' ? timezone : undefined
          }
        })).then(booking => {
          navigate(`/bookings/${booking.id}?lid=${location_id}&c=${booking.access_code}`);
          if (booking.status === 'confirmed') {
            dispatch(addToast('Booking created', 'success'));
          } else if (booking.status === 'requested') {
            dispatch(addToast('Booking requested', 'success'));
          }
          try {
            const event = {
              event: 'booking_created',
              booking_id: booking.id,
              location_name: locationData[booking.location_id]?.name || '',
              services: booking.services.map(bs => ({
                name: service.name.en,
                duration_minutes: DateTime.fromISO(bs.end).diff(DateTime.fromISO(bs.start)).as('minutes'),
                team_member_names: bs.bookable_ids.map(v_3 => bookableData[v_3]?.name)
              }))
            };
            if (window['dataLayer']) {
              window['dataLayer'].push(event);
            } else if (window['gtag']) {
              window['gtag']('event', 'booking');
            } else if (window['ga']) {
              window['ga']('send', 'event', 'booking', 'create');
            }
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
          }
          return booking;
        }, error => {
          if (error.response && error?.response?.status === 409) {
            // Update availability
            updateAvailability();
            setShowNotAvailable(true);
            setShowPolicy(false);
          } else if (error?.response?.status >= 500 || !error?.response?.data?.errors) {
            return {
              [FORM_ERROR]: 'Something went wrong'
            };
          }
          return {
            [FORM_ERROR]: 'Something went wrong'
          };
        }).then(result => {
          if (result?.[FORM_ERROR]) {
            return result;
          }
          setSavedFormData({});
          updateAvailability();
          return result;
        });
      }} data-sentry-element="Form" data-sentry-source-file="component.tsx">
          {({
          handleSubmit,
          submitting,
          values: values_0,
          submitError
        }) => {
          return <form onSubmit={handleSubmit} noValidate>
                <FormSpy subscription={{
              values: true
            }} onChange={props => {
              const {
                business_id,
                location_id: location_id_0,
                services,
                ...rest
              } = props.values;
              const {
                start: start_1,
                end: end_1,
                service_id: service_id_0,
                bookable_ids: bookable_ids_0,
                ...servicesRest
              } = services[0];

              // savedFormData = { ...rest, services: [servicesRest] };

              setSavedFormData({
                ...rest,
                services: [servicesRest]
              });
            }} />
                <Grid gridTemplateColumns={['1fr', '1fr']} gridGap={4}>
                  <Grid gridGap={2}>
                    <Flex alignItems="center">
                      <IconWrapper icon={faCalendar} size={1} mr={2} />{' '}
                      <Text suppressHydrationWarning={true}>
                        {DateTime.fromISO(values_0.services[0].start, {
                      zone: timezone
                      // locale: language,
                    }).toFormat("DDDD 'at' t (ZZZZ)")}
                      </Text>
                    </Flex>

                    {service.show_pricing && price !== null && <Flex alignItems="center">
                        <IconWrapper icon={faTags} size={1} mr={2} />{' '}
                        {price > 0 ? <Text>{currencyFormat(price, currency)}</Text> : <Text>No charge</Text>}
                      </Flex>}

                    {buildBookableDropdowns(requirements, false, bookableData).map((b, idx) => ({
                  label: b.label,
                  name: bookableData[values_0.services[0].bookable_ids[idx]]?.name || 'No preference',
                  hide: b.hide_for_clients,
                  is_staff: !!bookableData[values_0.services[0].bookable_ids[idx]]?.staff_member_id,
                  image_id: bookableData[values_0.services[0].bookable_ids[idx]]?.image_id
                })).filter(b_0 => !b_0.hide).map((bookable, idx_0) => <Flex key={idx_0} alignItems="center">
                          <Avatar name={bookable?.name} icon={bookable?.is_staff ? faUser : faCube} image={bookable?.image_id ? getCdnImageUrlFromId(bookable?.image_id) : null} size={30} mr={2} />
                          <Text>{bookable.name}</Text>
                        </Flex>)}
                  </Grid>

                  {submitError && <Callout color="alert">{submitError}</Callout>}

                  {bookingForm ? <FormRenderer value={schema} showPrivate={false} /> : <>
                      <Field name="client.firstname" validate={value => {
                  if (!value) {
                    return 'Enter a first name';
                  }
                }}>
                        {({
                    input,
                    meta
                  }) => <Box>
                            <Input {...input} label={<FormattedMessage id="firstName" defaultMessage={`First name`} />} type="text" error={meta.touched && meta.error} />
                          </Box>}
                      </Field>

                      <Field name="client.lastname" validate={value_0 => {
                  if (!value_0) {
                    return 'Enter a last name';
                  }
                }}>
                        {({
                    input: input_0,
                    meta: meta_0
                  }) => <Box>
                            <Input {...input_0} label={<FormattedMessage id="lastName" defaultMessage="Last name" />} type="text" error={meta_0.touched && meta_0.error} />
                          </Box>}
                      </Field>

                      <Field name="client.cellphone" validate={(value_1, values_1) => {
                  if (!value_1) {
                    return 'Enter a cellphone number';
                  }
                  if (value_1 && !isValidNumber(value_1)) {
                    return 'Enter a valid cellphone number';
                  }
                }}>
                        {({
                    input: input_1,
                    meta: meta_1
                  }) => <Box>
                            <Label mb={2} invalid={meta_1.touched && meta_1.error}>
                              <FormattedMessage id="Cellphone" defaultMessage={`Cellphone`} />
                            </Label>

                            <PhoneInput {...input_1} invalid={meta_1.touched && meta_1.error} />
                            {meta_1.error && meta_1.touched && <FormError>{meta_1.error}</FormError>}
                          </Box>}
                      </Field>

                      <Field name="client.email" validate={(value_2, values_2) => {
                  if (!value_2) {
                    return 'Enter an email address';
                  }
                  if (value_2 && !isEmail(value_2)) {
                    return 'Enter a valid email address';
                  }
                }}>
                        {({
                    input: input_2,
                    meta: meta_2
                  }) => <Box>
                            <Input {...input_2} label={<FormattedMessage id="Email" defaultMessage="Email" />} type="email" error={meta_2.touched && meta_2.error} />
                          </Box>}
                      </Field>
                    </>}

                  <Flex justifyContent="flex-end" gridColumn="1 / -1">
                    <Button loading={submitting} disabled={submitting} type="submit">
                      {(service.confirm_public_booking_policy === CONFIRM_PUBLIC_BOOKING_POLICY.automatic || price === 0 && service.confirm_public_booking_policy === CONFIRM_PUBLIC_BOOKING_POLICY.payment) && <FormattedMessage id="Public.BookView.book" defaultMessage="Book" />}
                      {service.confirm_public_booking_policy === CONFIRM_PUBLIC_BOOKING_POLICY.manual && <FormattedMessage id="Public.BookView.requestBooking" defaultMessage={`Request booking`} />}
                      {price > 0 && service.confirm_public_booking_policy === CONFIRM_PUBLIC_BOOKING_POLICY.payment && <FormattedMessage id="Public.BookView.payBooking" defaultMessage="Confirm & Pay" />}
                    </Button>
                  </Flex>
                </Grid>
                <StandardModal isOpen={showPolicy} close={() => setShowPolicy(false)} title={<FormattedMessage id="Public.BookView.bookingPolicyTitle" defaultMessage={`Booking policy`} />}>
                  {() => <>
                      <Text>
                        {(business.settings.booking_policy || '').split('\n').map((v_4, idx_1) => <p key={idx_1}>{v_4}</p>)}
                      </Text>

                      <Flex justifyContent="flex-end" mt={3}>
                        <Button color="gray" variant="flat" mr={2} onClick={() => setShowPolicy(false)}>
                          <FormattedMessage id="Back" defaultMessage={`Back`} />
                        </Button>

                        <Button loading={submitting} onClick={() => handleSubmit()} disabled={submitting}>
                          <FormattedMessage id="Public.BookView.AcceptAndBook" defaultMessage="Accept and book" />
                        </Button>
                      </Flex>
                    </>}
                </StandardModal>
              </form>;
        }}
        </Form>

        <StandardModal isOpen={showNotAvailable} close={() => setShowNotAvailable(false)} type="alert" title={<FormattedMessage id="Public.BookView.slotUnavailableModalHeader" defaultMessage="Slot unavailable" />} data-sentry-element="StandardModal" data-sentry-source-file="component.tsx">
          {() => <>
              <Text>
                <FormattedMessage id="Public.BookView.slotUnavailableModalDescription" defaultMessage="The slot you have selected is no longer available." />
              </Text>

              <Flex justifyContent="flex-end" mt={3}>
                <Button as={ReachLink} to={`/services/${serviceId_0}` + (locationId ? `?lid=${locationId}` : '') // TODO: put saved vaues in here?
            }>
                  <FormattedMessage id="Public.BookView.tryAnotherSlotButton" defaultMessage="Try another slot" />
                </Button>
              </Flex>
            </>}
        </StandardModal>
      </Grid>
    </>;
}