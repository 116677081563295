import { c as _c } from "react/compiler-runtime";
import { faChevronDown, faChevronRight, faLandmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { Link as ReachtLink } from 'react-router-dom';
import axios from 'shared/anaxios';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Box, Flex, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import settings from 'shared/settings';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { toBase58 } from 'shared/utils/b58';
import { currencyFormat } from 'shared/utils/currency';
import { useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { formPost, payfastRedirect, paymentMeta } from 'shared/payments';
import { useParams } from 'react-router';
const PayInvoice = () => {
  const $ = _c(45);
  const [paymentData, setPaymentData] = React.useState(null);
  const [openAccordion, setOpenAccordion] = React.useState(null);
  const [, setIsProcessingPayment] = React.useState(false);
  const {
    invoiceId
  } = useParams();
  const [invoiceStatus, setInvoiceStatus] = React.useState("loading");
  const business = useAppSelector(_temp);
  let t0;
  let t1;
  if ($[0] !== invoiceId) {
    t0 = () => {
      axios.get(`${settings.api2Root}/invoice-payment-data/${invoiceId}`).then(t2 => {
        const {
          data
        } = t2;
        setPaymentData(data);
        setInvoiceStatus("done");
      }).catch(() => {
        setInvoiceStatus("notfound");
      });
    };
    t1 = [invoiceId];
    $[0] = invoiceId;
    $[1] = t0;
    $[2] = t1;
  } else {
    t0 = $[1];
    t1 = $[2];
  }
  React.useEffect(t0, t1);
  if (invoiceStatus === "loading") {
    let t2;
    if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
      t2 = <Loader />;
      $[3] = t2;
    } else {
      t2 = $[3];
    }
    return t2;
  } else {
    if (invoiceStatus === "notfound") {
      let t2;
      if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
        t2 = <EmptyState heading="Invoice not found" />;
        $[4] = t2;
      } else {
        t2 = $[4];
      }
      return t2;
    }
  }
  const {
    total_amount,
    paid_amount,
    total_due,
    currency,
    payment_methods
  } = paymentData;
  let t2;
  let t3;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = [`
          'summary'
          'methods'
        `, `
          'methods summary'
        `];
    t3 = ["1fr", "1fr 300px"];
    $[5] = t2;
    $[6] = t3;
  } else {
    t2 = $[5];
    t3 = $[6];
  }
  let t4;
  if ($[7] !== business || $[8] !== currency || $[9] !== invoiceId || $[10] !== openAccordion || $[11] !== payment_methods || $[12] !== total_due) {
    t4 = total_due > 0 ? <Box gridArea="methods"><BorderBox pt={0}>{payment_methods.some(_temp2) ? <><Box px={4} py={8} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1"><Heading fontSize={2} fontWeight={600}>Choose a payment method</Heading></Box>{payment_methods.filter(_temp3).filter(_temp4).map((t5, idx) => {
            const {
              id,
              provider_data: t6,
              type,
              instructions
            } = t5;
            const {
              form_data,
              host,
              qrcode_url,
              qrcode_svg_url,
              client_id,
              payment_url
            } = t6;
            return <Box key={idx}><Grid p={4} gridGap={4} gridTemplateColumns="auto 1fr auto" sx={{
                "&:hover, &:active": {
                  backgroundColor: "gray.1"
                },
                cursor: "pointer"
              }} onClick={() => {
                if (type === "payfast") {
                  setOpenAccordion(null);
                  payfastRedirect(host, form_data);
                } else {
                  if (type == "wigwag") {
                    formPost(payment_url, {
                      invoice_id: invoiceId,
                      payment_method_id: id
                    });
                  } else {
                    if (type == "yoco") {
                      formPost(payment_url, {
                        invoice_id: invoiceId,
                        payment_method_id: id
                      });
                    } else {
                      setOpenAccordion(openAccordion === idx ? null : idx);
                    }
                  }
                }
              }}><Flex alignItems="center">{paymentMeta[type].icon}</Flex><Flex alignItems="center"><CroppedText fontFamily="heading" fontSize={2}>{paymentMeta[type].name}</CroppedText></Flex><Flex alignItems="center">{paymentMeta[type].inPage && <FontAwesomeIcon icon={openAccordion === idx ? faChevronDown : faChevronRight} />}</Flex></Grid><PayPalScriptProvider options={{
                clientId: client_id,
                currency,
                intent: "capture"
              }}>{openAccordion === idx && type == "paypal" && <Box p={4}><PayPalButtons style={{
                    layout: "horizontal"
                  }} createOrder={(data_0, actions) => {
                    setIsProcessingPayment(true);
                    return actions.order.create({
                      intent: "CAPTURE",
                      purchase_units: [{
                        amount: {
                          currency_code: currency,
                          value: total_due.toString()
                        }
                      }],
                      application_context: {
                        shipping_preference: "NO_SHIPPING"
                      }
                    });
                  }} onApprove={(details, data_1) => {
                    setIsProcessingPayment(true);
                    return axios.post(`${settings.api2Root}/client-paypal-transaction-complete/${id}`, {
                      order_id: details.orderID,
                      invoice_id: invoiceId
                    }).then(() => {
                      setTimeout(() => {
                        axios.get(`${settings.api2Root}/invoice-payment-data/${invoiceId}`).then(t7 => {
                          const {
                            data: data_2
                          } = t7;
                          setPaymentData(data_2);
                          setIsProcessingPayment(false);
                        });
                      }, 2000);
                    }).catch(() => setIsProcessingPayment(false));
                  }} /></Box>}</PayPalScriptProvider>{openAccordion === idx && type == "snapscan" && <Box p={4}><Link href={qrcode_url}><img alt="qr code" src={qrcode_svg_url} /></Link></Box>}{openAccordion === idx && type == "eft" && <Grid p={4}>{instructions.split("\n").map(_temp5)}</Grid>}</Box>;
          })}</> : <EmptyState minHeight="283px" heading={`${business.name} is not accepting online payments at the
                moment.`} callToAction={<Text>Please view your invoice for further instructions or{" "}<Link as={ReachtLink} to={`/contact`}>contact us</Link></Text>} />}</BorderBox></Box> : <Box gridArea="methods"><BorderBox textAlign="center"><CroppedText fontSize={2}>Your payment was received. Thank you.</CroppedText></BorderBox></Box>;
    $[7] = business;
    $[8] = currency;
    $[9] = invoiceId;
    $[10] = openAccordion;
    $[11] = payment_methods;
    $[12] = total_due;
    $[13] = t4;
  } else {
    t4 = $[13];
  }
  let t5;
  if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = <Box px={4} py={8} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1"><Heading fontSize={2} fontWeight={600}>Summary</Heading></Box>;
    $[14] = t5;
  } else {
    t5 = $[14];
  }
  let t6;
  if ($[15] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = <CroppedText>Total</CroppedText>;
    $[15] = t6;
  } else {
    t6 = $[15];
  }
  let t7;
  if ($[16] !== currency || $[17] !== total_amount) {
    t7 = currencyFormat(total_amount, currency);
    $[16] = currency;
    $[17] = total_amount;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  let t8;
  if ($[19] !== t7) {
    t8 = <Flex justifyContent="space-between" p={4}>{t6}<CroppedText>{t7}</CroppedText></Flex>;
    $[19] = t7;
    $[20] = t8;
  } else {
    t8 = $[20];
  }
  let t9;
  if ($[21] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = <CroppedText>Paid</CroppedText>;
    $[21] = t9;
  } else {
    t9 = $[21];
  }
  let t10;
  if ($[22] !== currency || $[23] !== paid_amount) {
    t10 = currencyFormat(paid_amount, currency);
    $[22] = currency;
    $[23] = paid_amount;
    $[24] = t10;
  } else {
    t10 = $[24];
  }
  let t11;
  if ($[25] !== t10) {
    t11 = <Flex justifyContent="space-between" p={4}>{t9}<CroppedText>{t10}</CroppedText></Flex>;
    $[25] = t10;
    $[26] = t11;
  } else {
    t11 = $[26];
  }
  let t12;
  if ($[27] === Symbol.for("react.memo_cache_sentinel")) {
    t12 = <CroppedText fontWeight={600}>Total due</CroppedText>;
    $[27] = t12;
  } else {
    t12 = $[27];
  }
  let t13;
  if ($[28] !== currency || $[29] !== total_due) {
    t13 = currencyFormat(total_due, currency);
    $[28] = currency;
    $[29] = total_due;
    $[30] = t13;
  } else {
    t13 = $[30];
  }
  let t14;
  if ($[31] !== t13) {
    t14 = <Flex justifyContent="space-between" px={4} py={8} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.1">{t12}<CroppedText fontWeight={600}>{t13}</CroppedText></Flex>;
    $[31] = t13;
    $[32] = t14;
  } else {
    t14 = $[32];
  }
  let t15;
  if ($[33] !== invoiceId) {
    t15 = settings.bypassPWAScope(`/i/${toBase58(invoiceId)}?&download=false`);
    $[33] = invoiceId;
    $[34] = t15;
  } else {
    t15 = $[34];
  }
  let t16;
  if ($[35] !== t15) {
    t16 = <Box p={4} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.1"><Button as="a" variant="outlined" color="gray" rel="noopener noreferrer" target="_blank" href={t15}>View invoice</Button></Box>;
    $[35] = t15;
    $[36] = t16;
  } else {
    t16 = $[36];
  }
  let t17;
  if ($[37] !== t11 || $[38] !== t14 || $[39] !== t16 || $[40] !== t8) {
    t17 = <Box gridArea="summary"><BorderBox p={0}>{t5}{t8}{t11}{t14}{t16}</BorderBox></Box>;
    $[37] = t11;
    $[38] = t14;
    $[39] = t16;
    $[40] = t8;
    $[41] = t17;
  } else {
    t17 = $[41];
  }
  let t18;
  if ($[42] !== t17 || $[43] !== t4) {
    t18 = <Grid gridTemplateAreas={t2} gridTemplateColumns={t3}>{t4}{t17}</Grid>;
    $[42] = t17;
    $[43] = t4;
    $[44] = t18;
  } else {
    t18 = $[44];
  }
  return t18;
};
export default PayInvoice;
function _temp(state) {
  return state.businesses.data[state.public.businessId];
}
function _temp2(p_1) {
  return paymentMeta[p_1.type]?.integrated;
}
function _temp3(p) {
  return p.show_on_invoice;
}
function _temp4(p_0) {
  return paymentMeta[p_0.type]?.integrated;
}
function _temp5(v, idx_0) {
  return <CroppedText key={idx_0} data-sentry-element="CroppedText" data-sentry-component="_temp5" data-sentry-source-file="component.tsx">{v}</CroppedText>;
}