import { c as _c } from "react/compiler-runtime";
import useComponentSize from '../hooks/component-size';
import React, { ReactNode } from 'react';
import FormError from '../form-error';
import { Box, BoxProps, Flex } from '../grid';
import { invalidInput } from '../input-style';
import Label from '../label';
interface InputProps {
  invalid?: boolean;
  label?: any;
}
export type StyledInputProps = InputProps & Omit<BoxProps, 'label'>;
export function StyledInput(t0) {
  const $ = _c(18);
  let __css;
  let invalid;
  let props;
  let ref;
  let t1;
  if ($[0] !== t0) {
    const {
      ref: t2,
      invalid: t3,
      label,
      as: t4,
      __css: t5,
      ...t6
    } = t0;
    ref = t2;
    invalid = t3;
    t1 = t4;
    __css = t5;
    props = t6;
    $[0] = t0;
    $[1] = __css;
    $[2] = invalid;
    $[3] = props;
    $[4] = ref;
    $[5] = t1;
  } else {
    __css = $[1];
    invalid = $[2];
    props = $[3];
    ref = $[4];
    t1 = $[5];
  }
  const as = t1 === undefined ? "input" : t1;
  let t2;
  if ($[6] !== invalid) {
    t2 = invalid ? invalidInput : {};
    $[6] = invalid;
    $[7] = t2;
  } else {
    t2 = $[7];
  }
  let t3;
  if ($[8] !== __css) {
    t3 = __css || {};
    $[8] = __css;
    $[9] = t3;
  } else {
    t3 = $[9];
  }
  let t4;
  if ($[10] !== t2 || $[11] !== t3) {
    t4 = {
      ...t2,
      ...t3
    };
    $[10] = t2;
    $[11] = t3;
    $[12] = t4;
  } else {
    t4 = $[12];
  }
  let t5;
  if ($[13] !== as || $[14] !== props || $[15] !== ref || $[16] !== t4) {
    t5 = <Box ref={ref} as={as} tx="inputs" variant="default" type="text" __css={t4} {...props} />;
    $[13] = as;
    $[14] = props;
    $[15] = ref;
    $[16] = t4;
    $[17] = t5;
  } else {
    t5 = $[17];
  }
  return t5;
}
type LabeledInputProps = {
  label?: string | ReactNode;
  error?: string;
  invalid?: boolean;
  prefix?: React.JSX.Element | string;
  suffix?: React.JSX.Element | string;
} & StyledInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'>;
const ContentWrapper = t0 => {
  const $ = _c(12);
  const {
    prefix,
    suffix,
    pr: t1,
    children
  } = t0;
  const pr = t1 === undefined ? 0 : t1;
  const ref = React.useRef(null);
  const {
    width
  } = useComponentSize(ref);
  const t2 = prefix ? `${width}px !important` : null;
  const t3 = suffix ? `calc(${width + 0}px + ${pr}) !important` : null;
  let t4;
  if ($[0] !== t2 || $[1] !== t3) {
    t4 = {
      position: "relative",
      width: "100%",
      "& > input": {
        paddingLeft: t2,
        paddingRight: t3
      }
    };
    $[0] = t2;
    $[1] = t3;
    $[2] = t4;
  } else {
    t4 = $[2];
  }
  let t5;
  if ($[3] !== prefix) {
    t5 = prefix && <Flex ref={ref} px={4} alignItems="center" sx={{
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      gap: 2
    }}>{prefix}</Flex>;
    $[3] = prefix;
    $[4] = t5;
  } else {
    t5 = $[4];
  }
  let t6;
  if ($[5] !== suffix) {
    t6 = suffix && <Flex ref={ref} pr={3} alignItems="center" sx={{
      position: "absolute",
      right: 0,
      top: 0,
      height: "100%",
      gap: 2
    }}>{suffix}</Flex>;
    $[5] = suffix;
    $[6] = t6;
  } else {
    t6 = $[6];
  }
  let t7;
  if ($[7] !== children || $[8] !== t4 || $[9] !== t5 || $[10] !== t6) {
    t7 = <Box sx={t4}>{t5}{children}{t6}</Box>;
    $[7] = children;
    $[8] = t4;
    $[9] = t5;
    $[10] = t6;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  return t7;
};
function LabeledInput(t0) {
  const $ = _c(48);
  let error;
  let id;
  let invalid;
  let label;
  let onBlur;
  let onFocus;
  let pr;
  let prefix;
  let ref;
  let rest;
  let suffix;
  let t1;
  if ($[0] !== t0) {
    ({
      ref,
      label,
      error,
      invalid,
      id,
      onFocus,
      onBlur,
      prefix,
      suffix,
      type: t1,
      pr,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = error;
    $[2] = id;
    $[3] = invalid;
    $[4] = label;
    $[5] = onBlur;
    $[6] = onFocus;
    $[7] = pr;
    $[8] = prefix;
    $[9] = ref;
    $[10] = rest;
    $[11] = suffix;
    $[12] = t1;
  } else {
    error = $[1];
    id = $[2];
    invalid = $[3];
    label = $[4];
    onBlur = $[5];
    onFocus = $[6];
    pr = $[7];
    prefix = $[8];
    ref = $[9];
    rest = $[10];
    suffix = $[11];
    t1 = $[12];
  }
  const type = t1 === undefined ? "text" : t1;
  const [isFocused, setFocus] = React.useState(false);
  let t2;
  if ($[13] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {};
    $[13] = t2;
  } else {
    t2 = $[13];
  }
  let wrapperProps = t2;
  if (prefix || suffix) {
    let t3;
    if ($[14] !== pr || $[15] !== prefix || $[16] !== suffix) {
      t3 = {
        prefix,
        suffix,
        pr
      };
      $[14] = pr;
      $[15] = prefix;
      $[16] = suffix;
      $[17] = t3;
    } else {
      t3 = $[17];
    }
    wrapperProps = t3;
  }
  let t3;
  if ($[18] !== error || $[19] !== id || $[20] !== invalid || $[21] !== isFocused || $[22] !== label || $[23] !== rest) {
    t3 = label && <Label mb={2} htmlFor={id || rest.name} isFocused={isFocused} invalid={invalid || !!error}>{label}</Label>;
    $[18] = error;
    $[19] = id;
    $[20] = invalid;
    $[21] = isFocused;
    $[22] = label;
    $[23] = rest;
    $[24] = t3;
  } else {
    t3 = $[24];
  }
  const t4 = id || rest.name;
  const t5 = invalid || !!error;
  let t6;
  if ($[25] !== onFocus) {
    t6 = e => {
      onFocus?.(e);
      setFocus(true);
    };
    $[25] = onFocus;
    $[26] = t6;
  } else {
    t6 = $[26];
  }
  let t7;
  if ($[27] !== onBlur) {
    t7 = e_0 => {
      onBlur?.(e_0);
      setFocus(false);
    };
    $[27] = onBlur;
    $[28] = t7;
  } else {
    t7 = $[28];
  }
  let t8;
  if ($[29] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = {
      borderRadius: 2,
      width: ContentWrapper ? "100%" : undefined
    };
    $[29] = t8;
  } else {
    t8 = $[29];
  }
  let t9;
  if ($[30] !== pr || $[31] !== ref || $[32] !== rest || $[33] !== t4 || $[34] !== t5 || $[35] !== t6 || $[36] !== t7 || $[37] !== type) {
    t9 = <StyledInput type={type} id={t4} ref={ref} invalid={t5} onFocus={t6} onBlur={t7} __css={t8} pr={pr} {...rest} />;
    $[30] = pr;
    $[31] = ref;
    $[32] = rest;
    $[33] = t4;
    $[34] = t5;
    $[35] = t6;
    $[36] = t7;
    $[37] = type;
    $[38] = t9;
  } else {
    t9 = $[38];
  }
  let t10;
  if ($[39] !== t9 || $[40] !== wrapperProps) {
    t10 = <ContentWrapper {...wrapperProps}>{t9}</ContentWrapper>;
    $[39] = t9;
    $[40] = wrapperProps;
    $[41] = t10;
  } else {
    t10 = $[41];
  }
  let t11;
  if ($[42] !== error) {
    t11 = error && <FormError>{error}</FormError>;
    $[42] = error;
    $[43] = t11;
  } else {
    t11 = $[43];
  }
  let t12;
  if ($[44] !== t10 || $[45] !== t11 || $[46] !== t3) {
    t12 = <>{t3}{t10}{t11}</>;
    $[44] = t10;
    $[45] = t11;
    $[46] = t3;
    $[47] = t12;
  } else {
    t12 = $[47];
  }
  return t12;
}
export default LabeledInput;