import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { useLocationId } from '../../hooks';
import React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import axios from 'shared/anaxios';
import { Avatar } from 'shared/avatar';
import Button from 'shared/button';
import Callout from 'shared/callout';
import { InputComponent } from 'shared/dynamic-fields/form-renderer';
import { Box, Flex, Grid } from 'shared/grid';
import Modal, { CloseButton } from 'shared/modal';
import settings from 'shared/settings';
import { CroppedText, Heading, Text } from 'shared/typography';
import { CLIENT_REQUEST_TYPES, EMPTY_OBJECT } from 'shared/utils/constants';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { useAppSelector } from '../../store';
import { BookStepHeading } from '../shared';
const RequestPrescriptionView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const formSchema = [{
    label: 'Firstname',
    name: 'client.firstname',
    type: 'textline',
    required: true
  }, {
    label: 'Lastname',
    name: 'client.lastname',
    type: 'textline',
    required: true
  }, {
    label: 'Email',
    name: 'client.email',
    type: 'email',
    required: true
  }, {
    label: 'Cellphone',
    name: 'client.cellphone',
    type: 'phone-number',
    required: true
  }, {
    label: 'Description',
    name: 'description',
    type: 'textarea',
    required: true
  }];
  const token = useAppSelector(state => state.session.token);
  const businessId = useAppSelector(state_0 => state_0.public.businessId);
  const [locationId] = useLocationId();
  const location = useAppSelector(state_1 => state_1.locations.data[locationId]);
  const timezone = location?.timezone;
  const language = useAppSelector(state_2 => state_2.userPreferences.language);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [openBookableId, setOpenBookableId] = React.useState(null);
  const bookableData = useAppSelector(state_3 => state_3.bookables.data);
  const locationBookables = React.useMemo(() => {
    return Object.values<any>(bookableData).filter(b => b.is_deleted == false).filter(b_0 => b_0.location_ids.includes(locationId)).filter(b_1 => !!b_1.staff_member_id);
  }, [bookableData, locationId]);
  const setBookableId = React.useCallback(bid => {
    setSearchParams(prev => ({
      ...Object.fromEntries(prev.entries()),
      bid
    }));
  }, [setSearchParams]);
  const bookableId = searchParams.get('bid');
  const openBookable = bookableData[openBookableId];
  const select = React.useCallback(bid_0 => {
    setOpenBookableId(null);
    setBookableId(bid_0);
  }, [setBookableId]);
  if (bookableId === null) {
    return <Grid gridGap={8}>
        <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')}>
          <FormattedMessage id="Public.BookView.selectServicePrompt" defaultMessage={'Select "Resource"'} />
        </BookStepHeading>

        <Grid gridTemplateColumns={['repeat( auto-fit, minmax(150px, 1fr) )']} justifyItems="center">
          {locationBookables.map(({
          name,
          id: bid_1
        }) => <Grid onClick={() => select(bid_1)} onKeyPress={e => e.key === 'Enter' && select(bid_1)} tabIndex={0} role="button" key={bid_1} sx={{
          cursor: 'pointer'
        }} justifyItems="center">
              <Avatar size={100} image={bookableData[bid_1]?.image_id ? getCdnImageUrlFromId(bookableData[bid_1].image_id) : null} icon={faUser} name={name} />
              <Grid gridGap={2}>
                <Heading fontWeight={500} fontSize={3} textAlign="center">
                  {name}
                </Heading>
                {bookableData[bid_1]?.description && <Flex justifyContent="center">
                    <Button onClick={e_0 => {
                e_0.stopPropagation();
                setOpenBookableId(bid_1);
              }} as="button" type="button" color="primary" variant="outlined" size="small">
                      <FormattedMessage id="About" defaultMessage="About" />
                    </Button>
                  </Flex>}
              </Grid>
            </Grid>)}
        </Grid>
        <Modal
      // title={openBookable?.name}
      isOpen={!!openBookableId} close={() => setOpenBookableId(null)} size="medium" props={{
        openBookable: openBookable || EMPTY_OBJECT
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}>
          {({
          openBookable: openBookable_0,
          close
        }) => <Grid borderRadius={2} p={4} gridGap={8}>
              <Box sx={{
            position: 'absolute',
            top: 0,
            right: 0
          }} p={4}>
                <CloseButton isDark={true} onClick={close} />
              </Box>
              <Grid>
                <Flex justifyContent="center">
                  <Avatar size={150} image={openBookable_0.image_id ? getCdnImageUrlFromId(openBookable_0.image_id) : null} name={openBookable_0.name} />
                </Flex>
                <Flex justifyContent="center">
                  <Box sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                overflowWrap: 'break-word',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical'
              }}>
                    <Text fontSize={3} fontFamily="heading">
                      {openBookable_0.name}
                    </Text>
                  </Box>
                </Flex>
              </Grid>

              <Grid>
                {openBookable_0.description.split('\n').map((v, idx) => <CroppedText key={idx}>{v}</CroppedText>)}
              </Grid>

              <Flex justifyContent="flex-end">
                <Button variant="flat" mr={2} onClick={close}>
                  Close
                </Button>
                <Button onClick={() => select(openBookableId)}>Select</Button>
              </Flex>
            </Grid>}
        </Modal>
      </Grid>;
  }
  return <Form onSubmit={values => {
    const requestBody = {
      request_type: CLIENT_REQUEST_TYPES.document,
      description: values.description,
      client: {
        ...values.client,
        language,
        timezone
      },
      business_id: businessId,
      location_id: locationId,
      bookable_id: bookableId
    };
    if (businessId) {
      axios.post(`${settings.api2Root}/client-requests`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => setIsSubmitted(true));
    }
  }} data-sentry-element="Form" data-sentry-component="RequestPrescriptionView" data-sentry-source-file="component.tsx">
      {({
      handleSubmit,
      submitting
    }) => <Grid>
          <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')}>
            {isSubmitted ? '' : 'Prescription request details'}
          </BookStepHeading>
          {isSubmitted ? <Callout color="green" mx={0}>
              <FormattedMessage id="Public.RequestPrescription.requestSubmitted" defaultMessage="Your prescription request has been submitted" />
            </Callout> : <Grid as="form" onSubmit={handleSubmit}>
              {formSchema.map((inputSchema, idx_0) => <InputComponent key={idx_0} schema={inputSchema} />)}

              <Flex justifyContent="flex-end">
                <Button type="submit" loading={submitting} disabled={submitting}>
                  <FormattedMessage id="Public.FormView.submitButton" defaultMessage="Submit" />
                </Button>
              </Flex>
            </Grid>}
        </Grid>}
    </Form>;
};
export default RequestPrescriptionView;