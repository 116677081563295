import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Flex, Grid } from 'shared/grid';
import Divider from '../../divider';
import Switch from '../../switch';
const Separator = t0 => {
  const $ = _c(3);
  const {
    menu
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <Box flex="1"><Divider my={4} gradient={false} /></Box>;
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== menu) {
    t2 = <Flex justifyContent="space-between">{t1}{menu}</Flex>;
    $[1] = menu;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  return t2;
};
export default Separator;
export const toString = value => {
  return '';
};
export const View = t0 => {
  const $ = _c(2);
  const {
    schema
  } = t0;
  let t1;
  if ($[0] !== schema.label) {
    t1 = <Box>{schema.label}</Box>;
    $[0] = schema.label;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
};
export const Edit = t0 => {
  const $ = _c(3);
  const {
    isEditing,
    menu
  } = t0;
  let t1;
  if ($[0] !== isEditing || $[1] !== menu) {
    t1 = <>{!isEditing ? <Separator menu={menu} /> : <Grid><Field name="show_on_print">{_temp2}</Field></Grid>}</>;
    $[0] = isEditing;
    $[1] = menu;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  return t1;
};
function _temp(t0) {
  const {
    input
  } = t0;
  return <Flex alignItems="center" data-sentry-element="Flex" data-sentry-component="_temp" data-sentry-source-file="index.tsx"><Switch size="small" {...input} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Break page before</Box></Flex>;
}
function _temp2(t0) {
  const {
    input: pInput
  } = t0;
  return <><Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx" data-sentry-element="Flex"><Switch size="small" {...pInput} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Show when printing</Box></Flex>{pInput.value && <Field name="has_page_break">{_temp}</Field>}</>;
}