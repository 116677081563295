import { c as _c } from "react/compiler-runtime";
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { StandardModal } from 'shared/modal';
import { ModalToastContext, OpenModalFn } from './context';

// type ShowModalFn

export const useGlobalModal = () => {
  const $ = _c(5);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const closeOnCleanupRefs = React.useRef(t0);
  const {
    openModal: openModal_
  } = React.useContext(ModalToastContext);
  let t1;
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => () => {
      closeOnCleanupRefs.current.forEach(_temp);
    };
    t2 = [];
    $[1] = t1;
    $[2] = t2;
  } else {
    t1 = $[1];
    t2 = $[2];
  }
  React.useEffect(t1, t2);
  let t3;
  if ($[3] !== openModal_) {
    t3 = (title, getContent, options) => {
      const closeRef = {
        current: null
      };
      const onClose = () => {
        closeOnCleanupRefs.current = closeOnCleanupRefs.current.filter(v => v !== closeRef.current);
        options?.onClose?.();
      };
      const close_0 = openModal_(title, getContent, {
        ...options,
        onClose
      });
      closeRef.current = close_0;
      if (options?.closeWithHookCleanup == true) {
        closeOnCleanupRefs.current.push(close_0);
      }
      return close_0;
    };
    $[3] = openModal_;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  const openModal = t3;
  return openModal;
};
export default function useModal() {
  const $ = _c(11);
  const [modalContent, setModalContent] = useState(null);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = () => {
      setModalContent(null);
    };
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const close = t0;
  let t1;
  const t2 = modalContent !== null;
  const t3 = modalContent?.title;
  const t4 = modalContent?.content;
  let t5;
  if ($[1] !== t4) {
    t5 = {
      content: t4
    };
    $[1] = t4;
    $[2] = t5;
  } else {
    t5 = $[2];
  }
  const t6 = modalContent?.size;
  let t7;
  if ($[3] !== t2 || $[4] !== t3 || $[5] !== t5 || $[6] !== t6) {
    t7 = <StandardModal isOpen={t2} close={close} title={t3} props={t5} size={t6}>{_temp2}</StandardModal>;
    $[3] = t2;
    $[4] = t3;
    $[5] = t5;
    $[6] = t6;
    $[7] = t7;
  } else {
    t7 = $[7];
  }
  t1 = t7;
  const modal = t1;
  let t8;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = (title, getContent, size) => {
      setModalContent({
        size,
        content: getContent({
          close
        }),
        title
      });
    };
    $[8] = t8;
  } else {
    t8 = $[8];
  }
  const showModal = t8;
  let t9;
  if ($[9] !== modal) {
    t9 = [modal, showModal, close];
    $[9] = modal;
    $[10] = t9;
  } else {
    t9 = $[10];
  }
  return t9;
}
function _temp2(t0) {
  const {
    content
  } = t0;
  return content;
}
function _temp(close) {
  return close();
}