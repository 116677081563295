import { c as _c } from "react/compiler-runtime";
import { faMapMarker } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { Avatar } from 'shared/avatar';
import EmptyState from 'shared/empty-state';
import { Box, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import { fetchService } from 'shared/redux/services/actions';
import { Heading } from 'shared/typography';
import { useAppDispatch, useAppSelector } from '../../store';
import { BookStepHeading } from '../shared';
import { useLocationId } from '../../hooks';
import { isUUID } from 'shared/utils/uuid';
import { toBase16 } from 'shared/utils/b58';
const Location = t0 => {
  const $ = _c(15);
  const {
    location
  } = t0;
  const [, setLocationId] = useLocationId();
  let t1;
  if ($[0] !== location.id || $[1] !== setLocationId) {
    t1 = () => setLocationId(location.id);
    $[0] = location.id;
    $[1] = setLocationId;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      cursor: "pointer"
    };
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const t3 = location.is_virtual ? null : `https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${location.gps[0]},${location.gps[1]}&zoom=14&size=150x150&sensor=false&markers=color:red|${location.gps[0]},${location.gps[1]}`;
  let t4;
  if ($[4] !== location.name || $[5] !== t3) {
    t4 = <Avatar size={100} image={t3} name={location.name} icon={faMapMarker} />;
    $[4] = location.name;
    $[5] = t3;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  let t5;
  if ($[7] !== location.name) {
    t5 = <Heading fontWeight={500} fontSize={3} textAlign="center">{location.name}</Heading>;
    $[7] = location.name;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== t4 || $[10] !== t5) {
    t6 = <Grid justifyItems="center" pb={4}>{t4}{t5}</Grid>;
    $[9] = t4;
    $[10] = t5;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] !== t1 || $[13] !== t6) {
    t7 = <Box as="button" onClick={t1} sx={t2}>{t6}</Box>;
    $[12] = t1;
    $[13] = t6;
    $[14] = t7;
  } else {
    t7 = $[14];
  }
  return t7;
};
const LocationSelector = () => {
  const location = useLocation();
  const {
    serviceId: serviceId_
  } = useParams();
  const serviceId_0 = React.useMemo(() => {
    let serviceId = serviceId_;
    if (!isUUID(serviceId)) {
      if (serviceId.length > 1 && serviceId.length < 32) {
        serviceId = toBase16(serviceId);
      } else if (serviceId.length !== 32) {
        // Not uuid
        serviceId = null;
      }
    }
    return serviceId;
  }, [serviceId_]);
  const [locationId, setLocationId] = useLocationId();
  // const location = useAppSelector((state) => state.location);

  const businessId = useAppSelector(state => state.public.businessId);

  // const serviceId = useAppSelector((state) => state.location.payload.serviceId);
  const service = useAppSelector(state_0 => state_0.services.data[serviceId_0]);
  const isLoading = useAppSelector(state_1 => [state_1.businesses.status, state_1.locations.status].includes('loading'));
  const bookableData = useAppSelector(state_2 => state_2.bookables.data);
  const locationData = useAppSelector(state_3 => state_3.locations.data);
  const businessLocationsWithResouces = React.useMemo(() => {
    const locationIdsHavingBookables = Object.values<any>(bookableData).filter(b => b.business_id == businessId).reduce((acc, curr) => {
      curr.location_ids.forEach(lId => {
        if (!acc.includes(lId)) {
          acc.push(lId);
        }
      });
      return acc;
    }, []);
    return Object.values<any>(locationData).filter(l => l.business_id == businessId).filter(l_0 => locationIdsHavingBookables.includes(l_0.id)).sort((a, b_0) => a.name < b_0.name ? -1 : 1);
  }, [bookableData, businessId, locationData]);
  const locationsProvidingThisService = React.useMemo(() => Object.values<any>(locationData).filter(l_1 => !l_1.is_deleted).filter(l_2 => service?.locations?.find(sl => sl.location_id === l_2.id)).sort((a_0, b_1) => a_0.name < b_1.name ? -1 : 1), [locationData, service?.locations]);
  const dispatch = useAppDispatch();
  const [notFound, setNotFound] = React.useState(false);
  const isScriptRequest = ['/request-prescription'].includes(location.pathname);
  const locations = isScriptRequest ? businessLocationsWithResouces : locationsProvidingThisService;
  React.useEffect(() => {
    if (!isScriptRequest && !service) {
      dispatch(fetchService(serviceId_0)).catch(e => {
        if (e.response?.status === 404) {
          setNotFound(true);
        }
      });
    }
  }, [dispatch, isScriptRequest, location.pathname, service, serviceId_0]);
  const firstAndOnlyLocationId = locationsProvidingThisService.length === 1 ? locationsProvidingThisService[0].id : null;
  React.useEffect(() => {
    if (firstAndOnlyLocationId) {
      setLocationId(firstAndOnlyLocationId);
    }
  }, [dispatch, firstAndOnlyLocationId, setLocationId]);
  if (notFound) {
    return <EmptyState heading={<FormattedMessage id="Public.ServiceView.serviceNotFound" defaultMessage="Service not found" />} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  return <Grid gridGap={4} data-sentry-element="Grid" data-sentry-component="LocationSelector" data-sentry-source-file="index.tsx">
      <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')} data-sentry-element="BookStepHeading" data-sentry-source-file="index.tsx">
        <FormattedMessage id="Public.LocationSelector.pickALocation" defaultMessage="Select a location to continue" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </BookStepHeading>

      <Grid gridTemplateColumns="repeat(auto-fit, minmax(150px, 1fr))" width="100%" p={8} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {locations.map((location_0, idx) => <Location key={idx} location={location_0} />)}
      </Grid>
    </Grid>;
};
export default LocationSelector;