import { c as _c } from "react/compiler-runtime";
import { faPenRuler } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH, faAt, faCalendar, faCheckSquare, faChevronCircleDown, faClock, faDotCircle, faGlobe, faGlobeAfrica, faHeading, faList, faMapMarker, faMobile, faPhotoVideo, faRectanglePortrait, faRectangleWide, faSignature, faTags, faUpload } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Box } from 'shared/grid';
import { Link } from 'shared/typography';
import Upsell from 'shared/upsell';
import { EMPTY_OBJECT } from 'shared/utils/constants';
import Callout from '../callout';
import { useHasFeature } from '../hooks/features';
import Label from '../label';
import { StandardModal } from '../modal';
import Address, { Edit as AddressEdit, toString as addressToString, View as AddressView } from './address';
import Checkbox, { Edit as CheckboxEdit, toString as checkboxToString, View as CheckboxView } from './checkbox';
import Checkboxes, { Edit as CheckboxesEdit, toString as checkboxesToString, View as CheckboxesView } from './checkboxes';
import Date, { Edit as DateEdit, toString as dateToString, View as DateView } from './date';
import Diagram, { Edit as DiagramEdit, toString as diagramToString, View as DiagramView } from './diagram';
import Dropdown, { Edit as DropdownEdit, toString as dropdownToString, View as DropdownView } from './dropdown';
import Email, { Edit as EmailEdit, toString as emailToString, View as EmailView } from './email';
import Heading, { Edit as HeadingEdit, toString as headingToString, View as HeadingView } from './heading';
import Language, { Edit as LanguageEdit, toString as languageToString, View as LanguageView } from './language';
import LocationList, { Edit as LocationListEdit, toString as locationListToString, View as LocationListView } from './location-list';
import MultipleChoice, { Edit as MultipleChoiceEdit, toString as multiplechoiceToString, View as MultipleChoiceView } from './multiplechoice';
import PhoneNumber, { Edit as PhoneNumberEdit, toString as phonenumberToString, View as PhoneNumberView } from './phone-number';
import Separator, { Edit as SeparatorEdit, toString as separatorToString, View as SeparatorView } from './separator';
import Signature, { Edit as SignatureEdit, toString as signatureToString, View as SignatureView } from './signature';
import Tags, { Edit as TagsEdit, toString as tagsToString, View as TagsView } from './tags';
import Text, { Edit as TextEdit, toString as textToString, View as TextView } from './text';
import Textarea, { Edit as TextareaEdit, toString as textareaToString, View as TextareaView } from './textarea';
import Textline, { Edit as TextlineEdit, toString as textlineToString, View as TextlineView } from './textline';
import Time, { Edit as TimeEdit, toString as timeToString, View as TimeView } from './time';
import Timezone, { Edit as TimezoneEdit, toString as timezoneToString, View as TimezoneView } from './timezone';
import Uploads, { Edit as UploadsEdit, toString as uploadsToString, View as UploadsView } from './uploads';
export const presentationalElements = ['heading', 'separator', 'text'];
export const fieldTemplates = [{
  icon: faHeading,
  type: 'heading',
  label: 'Heading',
  name: 'heading',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: '',
  value: '',
  group: 'presentation'
}, {
  icon: faPhotoVideo,
  type: 'text',
  label: 'Rich text',
  name: 'text',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: '',
  value: '',
  group: 'presentation'
}, {
  icon: faArrowsH,
  type: 'separator',
  label: 'Separator',
  name: 'separator',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: '',
  group: 'presentation'
}, {
  icon: faRectangleWide,
  type: 'textline',
  label: 'Short text entry',
  name: 'textline',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: ''
}, {
  icon: faRectanglePortrait,
  type: 'textarea',
  label: 'Long text entry',
  name: 'text_area',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faMobile,
  type: 'phone-number',
  label: 'Phone number',
  name: 'phone_number',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faAt,
  type: 'email',
  label: 'Email',
  name: 'email',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faChevronCircleDown,
  type: 'dropdown',
  label: 'Dropdown',
  name: 'dropdown',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2'],
  placeholder: 'Select'
}, {
  icon: faCheckSquare,
  type: 'checkbox',
  label: 'Checkbox',
  name: 'checkbox',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faList,
  type: 'checkboxes',
  label: 'Checkboxes',
  name: 'checkboxes',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2']
}, {
  icon: faDotCircle,
  type: 'multiplechoice',
  label: 'Multiple choice',
  name: 'multiplechoice',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2']
}, {
  icon: faCalendar,
  type: 'date',
  label: 'Date',
  name: 'date',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faClock,
  type: 'time',
  label: 'Time',
  name: 'time',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faMapMarker,
  type: 'address',
  label: 'Address',
  name: 'address',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  show_map: false,
  show_on_print: false,
  has_page_break: false
}, {
  icon: faSignature,
  type: 'signature',
  label: 'Signature',
  name: 'signature',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faPenRuler,
  type: 'diagram',
  label: 'Diagram',
  name: 'diagram',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faGlobe,
  type: 'language',
  label: 'Language',
  name: 'language',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faTags,
  type: 'tags',
  label: 'Tags',
  name: 'tags',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2']
}, {
  icon: faGlobeAfrica,
  type: 'timezone',
  label: 'Time zone',
  name: 'timezone',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faUpload,
  type: 'uploads',
  label: 'Uploads',
  name: 'uploads',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  max_number_uploads: 1
}];
const typeToStringFn = {
  heading: headingToString,
  separator: separatorToString,
  text: textToString,
  'location-list': locationListToString,
  timezone: timezoneToString,
  tags: tagsToString,
  language: languageToString,
  multiplechoice: multiplechoiceToString,
  checkboxes: checkboxesToString,
  checkbox: checkboxToString,
  dropdown: dropdownToString,
  time: timeToString,
  date: dateToString,
  textarea: textareaToString,
  address: addressToString,
  email: emailToString,
  'phone-number': phonenumberToString,
  textline: textlineToString,
  signature: signatureToString,
  diagram: diagramToString,
  uploads: uploadsToString
};
const typeToInputEditComponent = {
  heading: HeadingEdit,
  text: TextEdit,
  separator: SeparatorEdit,
  'location-list': LocationListEdit,
  timezone: TimezoneEdit,
  tags: TagsEdit,
  language: LanguageEdit,
  multiplechoice: MultipleChoiceEdit,
  checkboxes: CheckboxesEdit,
  checkbox: CheckboxEdit,
  dropdown: DropdownEdit,
  time: TimeEdit,
  date: DateEdit,
  textarea: TextareaEdit,
  address: AddressEdit,
  email: EmailEdit,
  'phone-number': PhoneNumberEdit,
  textline: TextlineEdit,
  signature: SignatureEdit,
  diagram: DiagramEdit,
  uploads: UploadsEdit
};
const typeToInputComponent = {
  heading: Heading,
  text: Text,
  separator: Separator,
  'location-list': LocationList,
  timezone: Timezone,
  tags: Tags,
  language: Language,
  multiplechoice: MultipleChoice,
  checkboxes: Checkboxes,
  checkbox: Checkbox,
  dropdown: Dropdown,
  time: Time,
  date: Date,
  textarea: Textarea,
  address: Address,
  email: Email,
  'phone-number': PhoneNumber,
  textline: Textline,
  signature: Signature,
  diagram: Diagram,
  uploads: Uploads
};
const typeToInputViewComponent = {
  heading: HeadingView,
  text: TextView,
  separator: SeparatorView,
  'location-list': LocationListView,
  timezone: TimezoneView,
  tags: TagsView,
  language: LanguageView,
  multiplechoice: MultipleChoiceView,
  checkboxes: CheckboxesView,
  checkbox: CheckboxView,
  dropdown: DropdownView,
  time: TimeView,
  date: DateView,
  textarea: TextareaView,
  address: AddressView,
  email: EmailView,
  'phone-number': PhoneNumberView,
  textline: TextlineView,
  signature: SignatureView,
  diagram: DiagramView,
  uploads: UploadsView
};
export const toString = (value, schema) => {
  const toString = typeToStringFn[schema.type] || (v => String(v) || '');
  return toString(value);
};
interface InputEditProps {
  className?: string;
  showAdvanced?: boolean;
  hideRequired?: boolean;
  showClientFieldReference?: boolean;
  isUniqueFieldName: any;
  isEditing: boolean;
  value: any;
  menu?: any;
  style?: any;
  disabled?: boolean;
}
const NotFound = () => null;
export const InputEditComponent = props => {
  const $ = _c(3);
  const InputComponent = typeToInputEditComponent[props.value?.type] || NotFound;
  let t0;
  if ($[0] !== InputComponent || $[1] !== props) {
    t0 = <InputComponent {...props} />;
    $[0] = InputComponent;
    $[1] = props;
    $[2] = t0;
  } else {
    t0 = $[2];
  }
  return t0;
};
export const InputComponent = t0 => {
  const $ = _c(9);
  const {
    schema,
    disableAutoComplete,
    variables: t1,
    nonCustomFields,
    getWrapper,
    menu
  } = t0;
  const variables = t1 === undefined ? EMPTY_OBJECT : t1;
  const form = useForm();
  const InputComponent = typeToInputComponent[schema.type] || NotFound;
  const t2 = nonCustomFields ? !nonCustomFields.includes(schema.name) : false;
  let t3;
  if ($[0] !== InputComponent || $[1] !== disableAutoComplete || $[2] !== form || $[3] !== getWrapper || $[4] !== menu || $[5] !== schema || $[6] !== t2 || $[7] !== variables) {
    t3 = <InputComponent variables={variables} schema={schema} form={form} disableAutoComplete={disableAutoComplete} isCustom={t2} getWrapper={getWrapper} menu={menu} />;
    $[0] = InputComponent;
    $[1] = disableAutoComplete;
    $[2] = form;
    $[3] = getWrapper;
    $[4] = menu;
    $[5] = schema;
    $[6] = t2;
    $[7] = variables;
    $[8] = t3;
  } else {
    t3 = $[8];
  }
  return t3;
};
const UpsellAttachmentsPlaceholder = t0 => {
  const $ = _c(14);
  const {
    schema
  } = t0;
  const isPublic = useSelector(_temp);
  const [isUpsellUploadsModalOpen, setIsUpsellUploadsModalOpen] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      flexGrow: 1
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== schema.label) {
    t2 = <Label sx={t1}>{schema.label}</Label>;
    $[1] = schema.label;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== isPublic) {
    t3 = <Box>{isPublic ? <Callout color="alert"><p>File uploads are currently disabled</p></Callout> : <Callout color="alert"><p>Your plan does not include file uploads.{" "}<Link as="button" type="button" onClick={() => setIsUpsellUploadsModalOpen(true)}>Upgrade now</Link>{" "}</p></Callout>}</Box>;
    $[3] = isPublic;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== t2 || $[6] !== t3) {
    t4 = <Box>{t2}{t3}</Box>;
    $[5] = t2;
    $[6] = t3;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  let t5;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = () => setIsUpsellUploadsModalOpen(false);
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== isUpsellUploadsModalOpen) {
    t6 = <StandardModal title="Form uploads" isOpen={isUpsellUploadsModalOpen} close={t5}>{_temp2}</StandardModal>;
    $[9] = isUpsellUploadsModalOpen;
    $[10] = t6;
  } else {
    t6 = $[10];
  }
  let t7;
  if ($[11] !== t4 || $[12] !== t6) {
    t7 = <>{t4}{t6}</>;
    $[11] = t4;
    $[12] = t6;
    $[13] = t7;
  } else {
    t7 = $[13];
  }
  return t7;
};
const FormRenderer = React.memo(function FormRenderer(t0) {
  const $ = _c(16);
  const {
    value: schemaItems,
    disableAutoComplete: t1,
    showPrivate: t2,
    variables: t3,
    nonCustomFields,
    getWrapper
  } = t0;
  const disableAutoComplete = t1 === undefined ? false : t1;
  const showPrivate = t2 === undefined ? true : t2;
  const variables = t3 === undefined ? EMPTY_OBJECT : t3;
  const hasAttachments = useHasFeature("attachments");
  let t4;
  if ($[0] !== disableAutoComplete || $[1] !== getWrapper || $[2] !== hasAttachments || $[3] !== nonCustomFields || $[4] !== schemaItems || $[5] !== showPrivate || $[6] !== variables) {
    let t5;
    if ($[8] !== showPrivate) {
      t5 = v => showPrivate || !v.private;
      $[8] = showPrivate;
      $[9] = t5;
    } else {
      t5 = $[9];
    }
    let t6;
    if ($[10] !== disableAutoComplete || $[11] !== getWrapper || $[12] !== hasAttachments || $[13] !== nonCustomFields || $[14] !== variables) {
      t6 = (schema, idx) => {
        if (!hasAttachments && schema.name == "uploads") {
          return <UpsellAttachmentsPlaceholder schema={schema} key={idx} />;
        }
        return <InputComponent getWrapper={getWrapper} variables={variables} schema={schema} key={idx} disableAutoComplete={disableAutoComplete} nonCustomFields={nonCustomFields} />;
      };
      $[10] = disableAutoComplete;
      $[11] = getWrapper;
      $[12] = hasAttachments;
      $[13] = nonCustomFields;
      $[14] = variables;
      $[15] = t6;
    } else {
      t6 = $[15];
    }
    t4 = schemaItems.filter(t5).map(t6);
    $[0] = disableAutoComplete;
    $[1] = getWrapper;
    $[2] = hasAttachments;
    $[3] = nonCustomFields;
    $[4] = schemaItems;
    $[5] = showPrivate;
    $[6] = variables;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  return t4;
});
export default FormRenderer;
const DisplayComponent = t0 => {
  const $ = _c(4);
  const {
    schema,
    value
  } = t0;
  const InputView = typeToInputViewComponent[schema.type] || NotFound;
  let t1;
  if ($[0] !== InputView || $[1] !== schema || $[2] !== value) {
    t1 = <InputView schema={schema} value={value} />;
    $[0] = InputView;
    $[1] = schema;
    $[2] = value;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  return t1;
};
export const FormViewOnlyRenderer = t0 => {
  const $ = _c(10);
  const {
    schema,
    values,
    showPresentational: t1
  } = t0;
  const showPresentational = t1 === undefined ? false : t1;
  const hasAttachments = useHasFeature("attachments");
  if (!values) {
    return null;
  }
  let t2;
  if ($[0] !== hasAttachments || $[1] !== schema || $[2] !== showPresentational || $[3] !== values) {
    let t3;
    if ($[5] !== showPresentational) {
      t3 = schemaItem => showPresentational || !presentationalElements.includes(schemaItem.type);
      $[5] = showPresentational;
      $[6] = t3;
    } else {
      t3 = $[6];
    }
    let t4;
    if ($[7] !== hasAttachments || $[8] !== values) {
      t4 = (schemaItem_0, idx, arr) => {
        if (!hasAttachments && schemaItem_0.name == "uploads") {
          return <UpsellAttachmentsPlaceholder schema={schemaItem_0} key={idx} />;
        }
        return <Box key={idx} mb={4}>{presentationalElements.includes(schemaItem_0.type) ? <InputComponent schema={schemaItem_0} /> : <><Label mb={2}>{schemaItem_0.label}</Label><DisplayComponent value={values[schemaItem_0.name]} schema={schemaItem_0} /></>}</Box>;
      };
      $[7] = hasAttachments;
      $[8] = values;
      $[9] = t4;
    } else {
      t4 = $[9];
    }
    t2 = schema.filter(t3).map(t4);
    $[0] = hasAttachments;
    $[1] = schema;
    $[2] = showPresentational;
    $[3] = values;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
};
function _temp(state) {
  return !!state.public;
}
function _temp2() {
  return <Upsell feature="attachments" data-sentry-element="Upsell" data-sentry-component="_temp2" data-sentry-source-file="form-renderer.tsx" />;
}