import { c as _c } from "react/compiler-runtime";
import { Global } from 'shared/styled/global';
import { GoogleMap, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import axios from '../../anaxios';
import { getIn } from 'final-form';
import debounce from 'lodash.debounce';
import React from 'react';
import { Field, Form, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import countries from 'shared/countries';
import Loader from 'shared/loader';
import Select from 'shared/select';
import { Link } from 'shared/typography';
import { disableAutoCompleteInput } from 'shared/utils/form';
import Button from '../../button';
import Divider from '../../divider';
import FormError from '../../form-error';
import { Box, Flex, Grid } from '../../grid';
import { useDebounce } from '../../hooks';
import Input, { StyledInput } from '../../input';
import Label from '../../label';
import Switch from '../../switch';
import Textarea from '../../textarea';
import provinces_ from '../../utils/za-provinces';
import { ClientFieldReference, FieldPrefixContext, LabelField, NameField, PrivateField, RequiredField } from '../shared';
import { FormattedMessage } from 'react-intl';
const PIN_PATH = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
const PinMarker = t0 => {
  const $ = _c(7);
  const {
    size: t1,
    color
  } = t0;
  const size = t1 === undefined ? 20 : t1;
  const t2 = `translate(${-size / 2}px,${-size}px)`;
  let t3;
  if ($[0] !== color || $[1] !== t2) {
    t3 = {
      pointerEvents: "none",
      cursor: "pointer",
      stroke: "#fff",
      strokeWidth: 2,
      fill: color,
      transform: t2
    };
    $[0] = color;
    $[1] = t2;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = <path d={PIN_PATH} />;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== size || $[5] !== t3) {
    t5 = <svg height={size} viewBox="0 0 24 24" style={t3}>{t4}</svg>;
    $[4] = size;
    $[5] = t3;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  return t5;
};
const geocodeToFormItems = item => {
  const location = item.address_components.reduce((agg, val) => {
    if (val.types.includes('street_number')) {
      agg['street_number'] = val.long_name;
    }
    if (val.types.includes('route')) {
      agg['street_name'] = val.long_name;
    }
    if (val.types.includes('sublocality_level_2')) {
      agg['suburb'] = val.long_name;
    }
    if (val.types.includes('locality')) {
      agg['city'] = val.long_name;
    }
    if (val.types.includes('administrative_area_level_1')) {
      agg['province'] = val.long_name;
    }
    if (val.types.includes('country')) {
      agg['country'] = val.short_name;
    }
    if (val.types.includes('postal_code')) {
      agg['postal_code'] = val.short_name;
    }
    return agg;
  }, {
    street_number: '',
    street_name: '',
    suburb: '',
    city: '',
    province: '',
    country: '',
    postal_code: '',
    gps: [item.geometry.location.lat, item.geometry.location.lng]
  });
  const {
    street_number,
    street_name,
    suburb,
    ...rest
  } = location;
  return {
    street_address: [[street_number, street_name].filter(v => v).join(' '), suburb].filter(v => v).join('\n'),
    ...rest
  };
};
const provinces = provinces_.map(p => ({
  label: p,
  value: p
})).sort((a, b) => a.label < b.label ? -1 : 1);
const loadPrefs: any = {
  googleMapsApiKey: __GOOGLE_MAPS_KEY__,
  libraries: ['places']
};
export const Address = ({
  schema,
  disabled,
  menu,
  style,
  className,
  disableAutoComplete,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  const form = useForm();
  const values = useFormState().values;
  const {
    latitude: defaultLatitude,
    longitude: defaultLongitude
  } = useSelector((state: any) => state.geoip);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const geocode = React.useCallback(debounce(({
    lat,
    lng
  }) => {
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: {
        // proximity: `${state.geoip.longitude},${state.geoip.latitude}`,
        latlng: `${lat},${lng}`,
        key: __GOOGLE_MAPS_KEY__
      }
    }).then(({
      data
    }) => {
      const results = data.results.map(r => ({
        ...r,
        text: r.formatted_address
      }));
      if (results.length) {
        const parts = geocodeToFormItems(results[0]);
        form.batch(() => {
          for (const [k, v] of Object.entries<any>(parts)) {
            form.change(`${name}.${k}`, v);
          }
        });
      }
    });
  }, 1000), [form, name]);
  const setCenter = React.useCallback(({
    lat,
    lng
  }) => {
    geocode({
      lat,
      lng
    });
    form.change(`${name}.gps`, [lat, lng]);
  }, [form, geocode, name]);
  const [lat, lng] = getIn(values, `${name}.gps`) || [];
  const center = React.useMemo(() => ({
    lat: lat || defaultLatitude || 0,
    lng: lng || defaultLongitude || 0
  }), [defaultLatitude, defaultLongitude, lat, lng]);
  const debouncedCenter = useDebounce(center, 1000);
  const mapRef = React.useRef<google.maps.Map>(null);
  const searchBoxRef = React.useRef<google.maps.places.SearchBox>(null);
  const onPlacesChanged = React.useCallback(() => {
    const place = searchBoxRef.current.getPlaces()[0];
    const parts = geocodeToFormItems(place);
    parts.gps = parts.gps.map(v => v());
    form.batch(() => {
      for (const [k, v] of Object.entries<any>(parts)) {
        form.change(`${name}.${k}`, v);
      }
    });
  }, [form, name]);
  const {
    isLoaded,
    loadError
  } = useLoadScript(loadPrefs);
  const bounds = React.useMemo(() => isLoaded ? new google.maps.LatLngBounds(new google.maps.LatLng(defaultLatitude, defaultLongitude), new google.maps.LatLng(defaultLatitude, defaultLongitude)) : null, [defaultLatitude, defaultLongitude, isLoaded]);
  if (!isLoaded && !loadError) {
    return <Loader />;
  }
  return <Box data-sentry-element="Box" data-sentry-component="Address" data-sentry-source-file="index.tsx">
      <Global styles={{
      '.pac-container': {
        zIndex: 60003
      }
    }} data-sentry-element="Global" data-sentry-source-file="index.tsx" />

      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Label sx={{
        flexGrow: 1
      }} data-sentry-element="Label" data-sentry-source-file="index.tsx">
          {schema.label}
        </Label>
        {menu}
      </Flex>
      <Divider mt={1} mb={4} gradient={false} data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
      <Grid style={style} className={`${className}`} gridTemplateColumns={['1fr', '1fr 1fr']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {schema.show_map && <>
            <Field name={`${name}.gps`}>
              {({
            input,
            meta
          }) => <Box gridColumn="1 / -1" sx={{
            height: '70vh',
            width: '100%',
            position: 'relative',
            zIndex: 1
          }}>
                  <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 1
            }}>
                    <PinMarker color="#dc4a3d" size={30} />
                  </Box>

                  {!loadError && <GoogleMap center={debouncedCenter} zoom={13} mapContainerStyle={{
              height: '70vh',
              width: '100%'
            }} options={{
              fullscreenControl: false,
              zoomControl: true,
              gestureHandling: 'cooperative',
              // scaleControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT
              }
            }} onCenterChanged={() => {
              const center = mapRef.current?.getCenter?.();
              if (center) {
                setCenter({
                  lng: center.lng(),
                  lat: center.lat()
                });
              }
            }} onLoad={map => {
              mapRef.current = map;
            }}>
                      {/* @ts-ignore */}
                      <StandaloneSearchBox bounds={bounds} onLoad={v => {
                searchBoxRef.current = v;
              }} onPlacesChanged={onPlacesChanged}>
                        <Box p={4} sx={{
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  pointerEvents: 'none'
                }}>
                          <StyledInput placeholder="Search..." sx={{
                    pointerEvents: 'all',
                    textOverflow: 'ellipses'
                  }} />
                        </Box>
                      </StandaloneSearchBox>
                    </GoogleMap>}
                </Box>}
            </Field>
          </>}

        <Field name={`${name}.street_address`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.streetAddressRequired" defaultMessage="Enter a street address" />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box gridColumn="1 / -1">
              <Label mb={2} invalid={meta.touched && meta.error}>
                <FormattedMessage id="DynamicFields.Address.streetAddress" defaultMessage="Street address" />
              </Label>

              <Textarea {...disableAutoCompleteInput(input, disableAutoComplete)} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error} />

              {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
            </Box>}
        </Field>

        <Field name={`${name}.city`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.cityRequired" defaultMessage="Enter a city" />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box>
              <Input {...disableAutoCompleteInput(input, disableAutoComplete)} label={<FormattedMessage id="DynamicFields.Address.city" defaultMessage="City" />} tabIndex={disabled ? -1 : undefined} type="text" error={meta.touched && meta.error} />
            </Box>}
        </Field>

        {values[name]?.country === 'ZA' ? <Field name={`${name}.province`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.provinceRequired" defaultMessage="Select a province" />;
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                {/* <Autocomplete
                        {...input}
                        label="Province"
                        tabIndex={disabled ? -1 : undefined}
                        itemToString={(v) => v || ''}
                        items={provinces}
                        invalid={meta.touched && meta.error}
                      /> */}

                <Select {...input} label={<FormattedMessage id="DynamicFields.Address.province" defaultMessage="Province" />} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error}>
                  <option></option>
                  {provinces.map(({
              value,
              label
            }) => <option key={value} value={value}>
                      {label}
                    </option>)}
                </Select>

                {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
              </Box>}
          </Field> : <Field name={`${name}.province`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.provinceorStateRequired" defaultMessage="Enter a province or state." />;
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                <Input {...disableAutoCompleteInput(input, disableAutoComplete)} label={<FormattedMessage id="DynamicFields.Address.provinceOrState" defaultMessage="Province/State" />} tabIndex={disabled ? -1 : undefined} type="text" error={meta.touched && meta.error} />
              </Box>}
          </Field>}

        <Field name={`${name}.postal_code`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.postalCodeRequired" defaultMessage="Enter a postal code." />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box>
              <Input {...disableAutoCompleteInput(input, disableAutoComplete)} label={<FormattedMessage id="DynamicFields.Address.postalCode" defaultMessage="Postal code" />} tabIndex={disabled ? -1 : undefined} type="text" error={meta.touched && meta.error} />
            </Box>}
        </Field>

        <Field name={`${name}.country`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.countryRequired" defaultMessage="Select a country." />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box>
              {/* <Autocomplete
                      {...input}
                      label="Country"
                      tabIndex={disabled ? -1 : undefined}
                       itemToString={(v) =>
                        countries.find((c) => c.value === v)?.label || ''
                      }
                      items={countries}
                      invalid={meta.touched && meta.error}
                    /> */}

              <Select {...input} label={<FormattedMessage id="DynamicFields.Address.country" defaultMessage="Country" />} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error}>
                <option></option>
                {countries.map(({
              value,
              label
            }) => <option key={value} value={value}>
                    {label}
                  </option>)}
              </Select>

              {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
            </Box>}
        </Field>
      </Grid>
      <Divider my={4} gradient={false} data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default Address;
export const toString = value => {
  return value ? ['street_address', 'city', 'province', 'country', 'postal_code'].map(v => value[v]).filter(v => v).map((v, idx) => v).join(', ') : '';
};
export const View = t0 => {
  const $ = _c(10);
  const {
    value,
    schema
  } = t0;
  let t1;
  if ($[0] !== schema.show_map || $[1] !== value) {
    t1 = schema.show_map && value?.gps && <Box><Link rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/maps?&z=16&q=${value.gps[0]}+${value.gps[1]}&ll=${value.gps[0]}+${value.gps[1]}`} sx={{
        width: "100%",
        display: "inline-block"
      }}><Box as="img" alt="map" borderRadius={2} src={`https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${value.gps[0]},${value.gps[1]}&zoom=14&size=200x200&sensor=false&markers=color:red|${value.gps[0]},${value.gps[1]}`} /></Link></Box>;
    $[0] = schema.show_map;
    $[1] = value;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] !== value) {
    t2 = value && ["street_address", "city", "province", "country", "postal_code"].map(v => value[v]).filter(_temp).map(_temp2);
    $[3] = value;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== t2) {
    t3 = <Box>{t2}</Box>;
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[7] !== t1 || $[8] !== t3) {
    t4 = <Box>{t1}{t3}</Box>;
    $[7] = t1;
    $[8] = t3;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  return t4;
};
export const Edit = t0 => {
  const $ = _c(13);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style,
    disableAutoComplete
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp3);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== disableAutoComplete || $[3] !== hideRequired || $[4] !== isEditing || $[5] !== isUniqueFieldName || $[6] !== menu || $[7] !== removable || $[8] !== showClientFieldReference || $[9] !== showMore || $[10] !== style || $[11] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp4}>{() => <Address schema={value} disabled={true} menu={menu} style={style} className={className} disableAutoComplete={disableAutoComplete} />}</Form> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><LabelField prefix="" /><Field name={"" ? `${""}.show_map` : "show_map"}>{_temp5}</Field>{!hideRequired && <RequiredField prefix="" />}{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button type="button" onClick={toggleMore} width="100%" variant="outlined" size="small">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = disableAutoComplete;
    $[3] = hideRequired;
    $[4] = isEditing;
    $[5] = isUniqueFieldName;
    $[6] = menu;
    $[7] = removable;
    $[8] = showClientFieldReference;
    $[9] = showMore;
    $[10] = style;
    $[11] = value;
    $[12] = t2;
  } else {
    t2 = $[12];
  }
  return t2;
};
function _temp(v_0) {
  return v_0;
}
function _temp2(v_1, idx) {
  return <Box key={idx} data-sentry-element="Box" data-sentry-component="_temp2" data-sentry-source-file="index.tsx">{v_1}</Box>;
}
function _temp3(v) {
  return !v;
}
function _temp4(value_0) {
  return null;
}
function _temp5(t0) {
  const {
    input
  } = t0;
  return <Flex alignItems="center" data-sentry-element="Flex" data-sentry-component="_temp5" data-sentry-source-file="index.tsx"><Switch size="small" {...input} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Show map</Box></Flex>;
}