import { faChevronDown, faChevronRight, faLandmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import Tippy from 'shared/tooltip';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'shared/anaxios';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Box, Flex, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import { fetchBooking } from 'shared/redux/bookings/actions';
import settings from 'shared/settings';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { toBase58 } from 'shared/utils/b58';
import { currencyFormat } from 'shared/utils/currency';
import { useAppDispatch, useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { useCantCancelBookingReason } from './hooks';
import { formPost, payfastRedirect, paymentMeta } from 'shared/payments';
export default function BookingPayView({
  booking,
  location,
  canCancel,
  isOwner,
  setShowCancelModal
}) {
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  const cantCancelReason = useCantCancelBookingReason(canCancel, business?.settings.disable_changes, booking?.services, isOwner);
  const dispatch = useAppDispatch();
  const [paymentData, setPaymentData] = React.useState(null);
  const [status, setStatus] = React.useState('loading');
  const [isProcessingPayment, setIsProcessingPayment] = React.useState(false);
  const fetchPaymentDetails = React.useCallback(() => {
    const request = () => axios.get(`${settings.api2Root}/booking-payment-data/${booking.id}`, {
      skipErrorResponseIntercept: true,
      skipErrorReportOnCode: {
        404: true
      }
    });
    const retry = ms => new Promise((resolve, reject) => setTimeout(() => request().then(v => resolve(v), e => reject(e)), ms));
    request().catch(() => retry(2000)).catch(() => retry(4000)).then(
    //@ts-ignore
    ({
      data
    }) => {
      setPaymentData(data);
      setStatus('done');
    }, () => {
      Sentry.captureMessage('Could not find booking payment data', 'error');
      setStatus('error');
    });
  }, [booking.id]);
  React.useEffect(() => {
    if (!booking.id) {
      setStatus('done');
      return;
    }
    fetchPaymentDetails();
  }, [booking.id, fetchPaymentDetails]);

  ////////////////////////

  const serviceData = useAppSelector(state_0 => {
    return state_0.services.data;
  });
  const billableItemData = useAppSelector(state_1 => state_1.billableItems.data);
  const invoiceSettings = useAppSelector(state_2 => state_2.invoiceSettings.data[state_2.public.businessId]);
  const items = React.useMemo(() => {
    return booking.services.map(({
      start,
      end,
      service_id,
      service_name
    }) => {
      const service = serviceData[service_id];
      const serviceLocation = service?.locations?.find(sl => sl.location_id === booking.location_id);
      if (!serviceLocation) {
        return null;
      }
      const duration = (DateTime.fromISO(end).valueOf() - DateTime.fromISO(start).valueOf()) / 60000;
      const sd = serviceLocation.service_durations.find(v_0 => v_0.duration === duration);
      if (!sd) {
        return null;
      }
      if (sd.pricing_model === 'hidden') {
        return null;
      }
      const tax_rate = invoiceSettings ? invoiceSettings.location_settings[invoiceSettings.is_location_specific ? booking.location_id : '*'].default_tax_rate : 0;
      if (sd.pricing_model === 'simple') {
        return {
          description: service_name,
          price: sd.price * (1 + tax_rate / 100)
        };
      }
      return {
        description: service_name,
        price: sd.billable_items.reduce((total, {
          billable_item_id,
          quantity
        }) => {
          const item = billableItemData[billable_item_id];
          if (!item) {
            return total;
          }
          return total + (quantity || 0) * (item.unit_price[booking.location_id] || 0);
        }, 0) * (1 + tax_rate / 100)
      };
    });
  }, [booking.services, invoiceSettings, billableItemData, serviceData, booking.location_id]);
  const [openAccordion, setOpenAccordion] = React.useState(null);
  if (status === 'loading') {
    return <Loader />;
  }
  if (status === 'error') {
    return <EmptyState heading="There is a delay in generating your invoice. Please wait a moment and click retry." callToAction={<Button onClick={fetchPaymentDetails}>Retry</Button>} />;
  }
  const {
    total_amount,
    paid_amount,
    total_due,
    currency,
    payment_methods
  } = paymentData;
  return <Box data-sentry-element="Box" data-sentry-component="BookingPayView" data-sentry-source-file="pay.tsx">
      <Grid gridTemplateAreas={[`
          'summary'
          'methods'
        `, `
          'methods summary'
        `]} gridTemplateColumns={['1fr', '1fr 300px']} data-sentry-element="Grid" data-sentry-source-file="pay.tsx">
        {total_due > 0 ? <Box gridArea="methods">
            <BorderBox p={0}>
              {payment_methods.some(p_1 => paymentMeta[p_1.type]?.integrated) ? <>
                  <Box px={4} py={8} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1">
                    <Heading fontSize={2} fontWeight={600}>
                      Choose a payment method
                    </Heading>
                  </Box>
                  {payment_methods.filter(p => p.is_available_to_clients).filter(p_0 => paymentMeta[p_0.type]?.integrated).map(({
              id,
              provider_data: {
                form_data,
                host,
                qrcode_url,
                qrcode_svg_url,
                client_id,
                payment_url
              },
              type,
              instructions
            }, idx) => <Box key={idx}>
                          <Grid p={4} gridGap={4} gridTemplateColumns={'auto 1fr auto'} sx={{
                cursor: 'pointer',
                '&:hover, &:active': {
                  backgroundColor: 'gray.1'
                }
              }} onClick={() => {
                if (type === 'payfast') {
                  setOpenAccordion(null);
                  payfastRedirect(host, form_data);
                } else if (type == 'wigwag') {
                  formPost(payment_url, {
                    invoice_id: paymentData?.invoice?.id,
                    booking_id: booking.id,
                    payment_method_id: id
                  });
                } else if (type == 'yoco') {
                  formPost(payment_url, {
                    invoice_id: paymentData?.invoice?.id,
                    booking_id: booking.id,
                    payment_method_id: id
                  });
                } else {
                  setOpenAccordion(openAccordion === idx ? null : idx);
                }
              }}>
                            <Flex alignItems="center">
                              {paymentMeta[type].icon}
                            </Flex>

                            <Flex alignItems="center">
                              <CroppedText fontFamily="heading" fontSize={2}>
                                {paymentMeta[type].name}
                              </CroppedText>
                            </Flex>

                            <Flex alignItems="center">
                              {paymentMeta[type].inPage && <FontAwesomeIcon icon={openAccordion === idx ? faChevronDown : faChevronRight} />}
                            </Flex>
                          </Grid>
                          <PayPalScriptProvider options={{
                clientId: client_id,
                currency,
                intent: 'capture'
              }}>
                            {openAccordion === idx && type == 'paypal' && <Box p={4}>
                                <PayPalButtons style={{
                    layout: 'horizontal'
                  }} createOrder={(data_0, actions) => {
                    setIsProcessingPayment(true);
                    return actions.order.create({
                      intent: 'CAPTURE',
                      purchase_units: [{
                        amount: {
                          currency_code: currency,
                          value: total_due.toString()
                        }
                      }],
                      application_context: {
                        shipping_preference: 'NO_SHIPPING'
                      }
                    });
                  }} onApprove={(details, data_1) => {
                    setIsProcessingPayment(true);
                    return axios.post(`${settings.api2Root}/client-paypal-transaction-complete/${id}`, {
                      order_id: details.orderID,
                      booking_id: booking.id
                    }).then(() => {
                      setTimeout(() => {
                        dispatch(fetchBooking(booking.id)).then(() => setIsProcessingPayment(false));
                      }, 2000);
                    }).catch(() => setIsProcessingPayment(false));
                  }} />
                              </Box>}
                          </PayPalScriptProvider>
                          {openAccordion === idx && type == 'snapscan' && <Box p={4}>
                              <Link href={qrcode_url}>
                                <img alt="qr code" src={qrcode_svg_url} />
                              </Link>
                            </Box>}

                          {openAccordion === idx && type == 'eft' && <Grid p={4}>
                              {instructions.split('\n').map((v_1, idx_0) => <CroppedText key={idx_0}>{v_1}</CroppedText>)}
                            </Grid>}
                        </Box>)}
                </> : <EmptyState minHeight="323px" heading={`${business.name} is not accepting online payments at the
              moment.`} callToAction={<Text>
                      Please view your invoice for further instructions or{' '}
                      <Link as={ReachLink} to={`/contact` + (location['id'] ? `?lid=${location['id']}` : '')}>
                        contact us
                      </Link>
                    </Text>} />}
            </BorderBox>
          </Box> : <Box gridArea="methods">
            <BorderBox textAlign="center">
              <CroppedText fontSize={2}>
                Your payment was received. Thank you.
              </CroppedText>
            </BorderBox>
          </Box>}
        <Box gridArea="summary" data-sentry-element="Box" data-sentry-source-file="pay.tsx">
          <BorderBox p={0} data-sentry-element="BorderBox" data-sentry-source-file="pay.tsx">
            <Box px={4} py={8} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1" data-sentry-element="Box" data-sentry-source-file="pay.tsx">
              <Heading fontSize={2} fontWeight={600} data-sentry-element="Heading" data-sentry-source-file="pay.tsx">
                Summary
              </Heading>
            </Box>

            {items.filter(v_2 => v_2).map(({
            description,
            price
          }, idx_1) => <Flex justifyContent="space-between" p={4} key={idx_1}>
                  <CroppedText flex="1 1 0">{description}</CroppedText>
                  <CroppedText>{currencyFormat(price, currency)}</CroppedText>
                </Flex>)}

            {paid_amount > 0 && <Flex justifyContent="space-between" p={4}>
                <CroppedText>Paid</CroppedText>
                <CroppedText>
                  {currencyFormat(paid_amount, currency)}
                </CroppedText>
              </Flex>}

            <Flex justifyContent="space-between" px={4} py={8} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.1" data-sentry-element="Flex" data-sentry-source-file="pay.tsx">
              <CroppedText fontWeight={600} data-sentry-element="CroppedText" data-sentry-source-file="pay.tsx">Total due</CroppedText>
              <CroppedText fontWeight={600} data-sentry-element="CroppedText" data-sentry-source-file="pay.tsx">
                {currencyFormat(total_due, currency)}
              </CroppedText>
            </Flex>

            <Box p={4} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.1" data-sentry-element="Box" data-sentry-source-file="pay.tsx">
              <Tippy disabled={canCancel || booking.status == 'cancelled'} content={cantCancelReason} data-sentry-element="Tippy" data-sentry-source-file="pay.tsx">
                <Box data-sentry-element="Box" data-sentry-source-file="pay.tsx">
                  <Button color="alert" variant="outlined" onClick={() => setShowCancelModal(true)} width="100%" disabled={!canCancel} data-sentry-element="Button" data-sentry-source-file="pay.tsx">
                    <FormattedMessage id="Cancel booking" defaultMessage="Cancel booking" data-sentry-element="FormattedMessage" data-sentry-source-file="pay.tsx" />
                  </Button>
                </Box>
              </Tippy>
            </Box>
            <Box pb={4} pl={3} pr={3} data-sentry-element="Box" data-sentry-source-file="pay.tsx">
              <Button as="a" variant="outlined" color="gray" rel="noopener noreferrer" target="_blank" href={settings.bypassPWAScope(`/i/${toBase58(paymentData?.invoice?.id)}?&download=false`)} data-sentry-element="Button" data-sentry-source-file="pay.tsx">
                View invoice
              </Button>
            </Box>
          </BorderBox>
        </Box>
      </Grid>
    </Box>;
}