import React from 'react';
import Input from 'shared/input';

// Only hours and minutes, no timezone or UTC
const validateRegex = /^[0-2]\d:[0-5]\d$/;
interface TimeOnlyPickerProps {
  value: string;
  onChange: (value: string) => any;
  style?: React.CSSProperties;
  className?: string;
  earliest?: string;
  latest?: string;
  onBlur?: (e: any) => void;
  inputRef?: (instance: HTMLInputElement) => any;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
}
const validateRange = props => {
  const isValid = (props.value || '').match(validateRegex);
  if (!isValid) {
    return true;
  }
  if (props.earliest) {
    const isEarliestValid = (props.earliest || '').match(validateRegex);
    if (isEarliestValid && props.earliest > props.value) {
      props.onChange(props.earliest);
      return false;
    }
  } else if (props.latest) {
    const isLatestValid = (props.latest || '').match(validateRegex);
    if (isLatestValid && props.latest < props.value) {
      props.onChange(props.latest);
      return false;
    }
  }
  return true;
};
export default class TimeOnlyPicker extends React.Component<TimeOnlyPickerProps, any> {
  static dateInputType = 'time';
  static dateFmt = 'HH:mm';
  state = {
    editing: false,
    valueText: ''
  };
  static getDerivedStateFromProps(props, state) {
    if (state.editing || !validateRange(props)) {
      return {};
    }
    const value = props.value;
    const isValid = (value || '').match(validateRegex);
    let valueText = '';
    if (isValid) {
      valueText = props.value;
    }
    return {
      valueText
    };
  }
  onBlur = () => {
    this.setState({
      editing: false
    });
    const isPrevValueValid = (this.props.value || '').match(validateRegex);
    if (this.state.valueText.match(validateRegex)) {
      this.props.onChange(this.state.valueText);
    } else if (isPrevValueValid) {
      // this.setStateFromProps(this.props);
      this.props.onChange(this.props.value);
    }
  };
  render() {
    const {
      value,
      onChange,
      earliest,
      latest,
      onBlur,
      inputRef,
      ...rest
    } = this.props;
    return <Input {...rest} step="300" ref={inputRef} placeholder={'YYYY-MM-DD'} type={TimeOnlyPicker.dateInputType} value={this.state.valueText} onFocus={() => this.setState({
      editing: true
    })} onBlur={e => {
      this.onBlur();
      if (onBlur) {
        onBlur(e);
      }
    }} onChange={v => this.setState({
      valueText: v.target.value
    })} data-sentry-element="Input" data-sentry-component="TimeOnlyPicker" data-sentry-source-file="index.tsx" />;
  }
}