import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Box, BoxProps, Flex } from '../grid';
import { Text } from '../typography';

// https://codepen.io/jensimmons/pen/KKPzxJa?editors=1100

type CheckboxProps = {
  size?: number;
} & Omit<BoxProps, 'size'>;
const Checkbox = props => {
  const $ = _c(23);
  const size = props.size || 16;
  const t0 = `${size}px`;
  const t1 = `${size}px`;
  const t2 = `${size * 0.1}px solid`;
  let t3;
  if ($[0] !== t2) {
    t3 = {
      border: t2,
      borderColor: "gray.3"
    };
    $[0] = t2;
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  const t4 = `${size * 0.1}px solid`;
  let t5;
  if ($[2] !== t4) {
    t5 = {
      backgroundColor: "brand3.3",
      border: t4,
      borderColor: "brand3.4"
    };
    $[2] = t4;
    $[3] = t5;
  } else {
    t5 = $[3];
  }
  const t6 = `0 0 0 ${size * 0.1}px rgba(0, 0, 0, 0.12)`;
  let t7;
  if ($[4] !== t6) {
    t7 = {
      boxShadow: t6
    };
    $[4] = t6;
    $[5] = t7;
  } else {
    t7 = $[5];
  }
  let t8;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = {
      position: "relative",
      backgroundColor: "brand3.7"
    };
    $[6] = t8;
  } else {
    t8 = $[6];
  }
  const t9 = `${size * 0.19}px`;
  const t10 = `${size * 0.25}px`;
  const t11 = `${size * 0.5}px`;
  const t12 = `${size * 0.2}px`;
  const t13 = `${size * 0.15}px`;
  let t14;
  if ($[7] !== t10 || $[8] !== t11 || $[9] !== t12 || $[10] !== t13 || $[11] !== t9) {
    t14 = {
      position: "absolute",
      left: t9,
      top: t10,
      content: "''",
      width: t11,
      height: t12,
      borderWidth: t13,
      borderStyle: "solid",
      borderColor: "invertedText",
      borderRight: "none",
      borderTop: "none",
      transform: "rotate(-45deg)"
    };
    $[7] = t10;
    $[8] = t11;
    $[9] = t12;
    $[10] = t13;
    $[11] = t9;
    $[12] = t14;
  } else {
    t14 = $[12];
  }
  let t15;
  if ($[13] !== t0 || $[14] !== t1 || $[15] !== t14 || $[16] !== t3 || $[17] !== t5 || $[18] !== t7) {
    t15 = {
      WebkitAppearance: "none",
      width: t0,
      height: t1,
      cursor: "pointer",
      borderRadius: 2,
      padding: 0,
      outline: "none",
      display: "inline-block",
      verticalAlign: "text-top",
      "&:not(:hover):not(:focus):not(:checked)": t3,
      "&:hover:not(:checked)": t5,
      "&:focus": t7,
      "&:checked": t8,
      "&:checked::after": t14
    };
    $[13] = t0;
    $[14] = t1;
    $[15] = t14;
    $[16] = t3;
    $[17] = t5;
    $[18] = t7;
    $[19] = t15;
  } else {
    t15 = $[19];
  }
  let t16;
  if ($[20] !== props || $[21] !== t15) {
    t16 = <Box as="input" type="checkbox" sx={t15} {...props} />;
    $[20] = props;
    $[21] = t15;
    $[22] = t16;
  } else {
    t16 = $[22];
  }
  return t16;
};
export const LabeledCheckbox = t0 => {
  const $ = _c(12);
  let label;
  let props;
  if ($[0] !== t0) {
    ({
      label,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = label;
    $[2] = props;
  } else {
    label = $[1];
    props = $[2];
  }
  const size = props.size || 16;
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      gap: "8px"
    };
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const t2 = `0 0 ${size}px`;
  let t3;
  if ($[4] !== props || $[5] !== t2) {
    t3 = <Checkbox {...props} flex={t2} />;
    $[4] = props;
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[7] !== label) {
    t4 = <Text flex="1 1 100%">{label}</Text>;
    $[7] = label;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  let t5;
  if ($[9] !== t3 || $[10] !== t4) {
    t5 = <Flex as="label" alignItems="center" sx={t1}>{t3}{t4}</Flex>;
    $[9] = t3;
    $[10] = t4;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  return t5;
};
export default Checkbox;